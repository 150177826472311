import { useState } from "react";
import { Link } from "react-router-dom";

// Types
import { CoverRequest, PreOrder, Song } from "../../../types/types";

// Components
import Modal from "../../../../../common/Modal";
import PreOrderForm from "./Form";
import ProductCard from "./ProductCard";
import Button from "../../../../../common/Button";

// Assets
import ShoppingCartSuccessful from "../../../../Purchase/views/components/Icons/ShoppingCartSuccessful";

const PreOrderModal = ({
  isOpen,
  onClose,
  selectedColor,
  coverRequest,
  song,
}: {
  isOpen: boolean;
  onClose: () => void;
  selectedColor: "dark" | "light";
  coverRequest: CoverRequest | null;
  song: Song | null;
}) => {
  const [order, setOrder] = useState<PreOrder | undefined>(undefined);

  const callback = (res: any) => {
    setOrder(res?.order);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        {order !== undefined ? (
          <>
            <div className="w-full flex flex-col items-center text-center gap-4 mt-6">
              <div className="bg-[#212B1E] p-4 rounded-full">
                <ShoppingCartSuccessful />
              </div>
              <h3 className="font-extrabold lg:text-xl text-xl font-Pinar">
                پیش‌سفارش شما با موفقیت ثبت شد!
              </h3>
              <p>با شما برای تکمیل سفارش در ارتباط خواهیم بود.</p>
              <p className="font-bold rounded-lg px-8 py-2 border border-white font-LightPinar">
                کد پیش‌سفارش: <span>{order?.ref}</span>
              </p>
              <Link to="/" className="w-full mt-10">
                <Button appearance="primary" shouldFitContainer={true}>
                  بازگشت به صفحه اصلی
                </Button>
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className="mb-10">
              <h2 className="text-2xl font-Pinar mt-4 mb-5">
                پیش‌سفارش تی‌شرت
              </h2>

              <p className="mb-3">
                خیلی خوشحالیم که طرح منحصر به فردت رو پسندیدی. ما در حال حاضر
                در مرحله پیش‌سفارش هستیم؛ برای همین طرح منحصر به فردت رو ذخیره
                می‌کنیم و چون یه خوش‌سلیقه‌ی کنجکاو هستی، ظرف ۳۰ روز آینده با یه
                تخفیف <strong>۳۵ درصدی</strong> به سراغت میایم که سفارشت رو
                تکمیل کنی.
              </p>

              <p>
                اما قبلش نیاز داریم که یک راه ارتباطی بهمون معرفی کنی. پس ممنون
                می‌شیم که شماره تماست رو در فرم زیر ثبت کنی. دوست داریم بدونی که
                توی این مرحله قرار نیست هیچ مبلغی پرداخت کنی پس با خیال راحت
                اطلاعاتت رو ثبت کن.
              </p>
            </div>

            <ProductCard selectedColor={selectedColor} song={song} />
            <PreOrderForm
              coverRequest={coverRequest}
              selectedColor={selectedColor}
              handleClose={onClose}
              callback={callback}
            />
          </>
        )}
      </Modal>
    </>
  );
};

export default PreOrderModal;
