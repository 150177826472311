import React, { forwardRef } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";

// Components
import Button from "../../../common/Button";

// Icons
import Person from "./icons/Person";
import Call from "./icons/Call";
import Email from "./icons/Email";

// Types
import { FormDataType, InputType, SectionTitleType } from "../types";
import { schema } from "../types/schema";

// MockData
import mockData from "../../../mockData/mockData.json";

const shippingCost = {
  standard: "۱۰۰,۰۰۰",
  express: "۱۰۰,۰۰۰",
};

// HOC Input And Sections

const Input = forwardRef<HTMLInputElement, InputType>(
  ({ IconComponent, type, placeholder, id, ...rest }, ref) => {
    return (
      <div className="w-full relative">
        {IconComponent && (
          <div className="absolute top-[50%] -translate-y-2/4 right-4 opacity-50 scale-90 min-w-6">
            <IconComponent />
          </div>
        )}
        <input
          id={id}
          type={type}
          className={`outline-none bg-gray35 rounded-lg py-3 w-full ${
            IconComponent ? "px-12" : "px-4"
          }
        placeholder:font-normal placeholder:text-base placeholder:opacity-50 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none `}
          placeholder={placeholder}
          ref={ref}
          {...rest}
        />
      </div>
    );
  }
);

const SectionTitle = ({
  children,
  title,
  isFirstComponent,
}: SectionTitleType) => {
  return (
    <div>
      <div className={`w-full relative ${isFirstComponent ? "mt-0" : "mt-16"}`}>
        <div className="border-b w-full absolute top-[50%] rounded-3xl border-opacity-50"></div>
        <h5 className="font-Pinar font-semibold w-fit pl-8 text-2xl bg-primary-dark relative">
          {title}
        </h5>
      </div>
      <div className={`mt-10 gap-4 flex flex-wrap w-full`}>{children}</div>
    </div>
  );
};



// Start Component

const CheckoutIndex = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormDataType>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FormDataType> = async (data) => {
    console.log(data);
    try {
      const response = await axios.post("example api", data);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="grid lg:grid-cols-6 grid-cols-1 gap-8 mb-20"
    >
      <div className="lg:col-span-4 col-span-1">
        <SectionTitle title="جزئیات حساب" isFirstComponent>
          <div className="xl:min-w-[calc(50%-1rem)] min-w-full">
            <Input
              id="name"
              placeholder="نام"
              type="text"
              IconComponent={Person}
              {...register("name", { required: true })}
            />
            {errors.name && (
              <p className="text-danger-dark mt-2 text-sm">
                {errors.name.message}
              </p>
            )}
          </div>
          <div className="xl:min-w-[calc(50%-1rem)] min-w-full">
            <Input
              id="lastName"
              placeholder="نام خانوادگی"
              type="text"
              IconComponent={Person}
              {...register("lastName", { required: true })}
            />
            {errors.lastName && (
              <p className="text-danger-dark mt-2 text-sm">
                {errors.lastName.message}
              </p>
            )}
          </div>
          <div className="xl:min-w-[calc(50%-1rem)] min-w-full">
            <Input
              id="phoneNumber"
              placeholder="شماره تلفن"
              type="number"
              IconComponent={Call}
              {...register("phoneNumber", { required: true })}
            />
            {errors.phoneNumber && (
              <p className="text-danger-dark mt-2 text-sm">
                {errors.phoneNumber.message}
              </p>
            )}
          </div>
          <div className="xl:min-w-[calc(50%-1rem)] min-w-full">
            <Input
              id="email"
              placeholder="ایمیل"
              type="email"
              IconComponent={Email}
              {...register("email", { required: true })}
            />
            {errors.email && (
              <p className="text-danger-dark mt-2 text-sm">
                {errors.email.message}
              </p>
            )}
          </div>
        </SectionTitle>
        <SectionTitle title="جزئیات آدرس">
          <div className="xl:min-w-[calc(50%-1rem)] min-w-full">
            <select
              className="outline-none bg-gray35 rounded-lg py-3 w-full px-4
        placeholder:font-normal placeholder:text-base placeholder:opacity-50"
              id="state"
              {...register("state", { required: "استان خود را وارد کنید" })}
            >
              <option selected value="" disabled>
                شهر خود را انتخاب کنید
              </option>
              {mockData.state.map((item, index) => (
                <option key={index} value={item.provinceName}>
                  {item.provinceName}
                </option>
              ))}
            </select>
            {errors.state && (
              <p className="text-danger-dark mt-2 text-sm">
                {errors.state.message}
              </p>
            )}
          </div>
          <div className="xl:min-w-[calc(50%-1rem)] min-w-full">
            <Input
              {...register("city", { required: true })}
              id="city"
              placeholder="شهر"
              type="text"
            />
            {errors.city && (
              <p className="text-danger-dark mt-2 text-sm">
                {errors.city.message}
              </p>
            )}
          </div>
          <div className="xl:min-w-[calc(50%-1rem)] min-w-full">
            <Input
              {...register("postalCode", { required: true })}
              id="postalCode"
              placeholder="کدپستی"
              type="text"
            />
            {errors.postalCode && (
              <p className="text-danger-dark mt-2 text-sm">
                {errors.postalCode.message}
              </p>
            )}
          </div>
          <div className="xl:min-w-[calc(50%-1rem)] min-w-full">
            <Input
              {...register("homePhoneNumber")}
              id="homePhoneNumber"
              placeholder="تلفن ثابت ( اختیاری )"
              type="text"
            />
            {errors.homePhoneNumber && (
              <p className="text-danger-dark mt-2 text-sm">
                {errors.homePhoneNumber.message}
              </p>
            )}
          </div>
          <div className="w-full">
            <textarea
              id="address"
              rows={8}
              placeholder="آدرس"
              className={`outline-none bg-gray35 rounded-lg py-3 lg:w-[98%] w-full px-6
        placeholder:font-normal placeholder:text-base placeholder:opacity-50`}
              {...register("address", { required: true })}
            />
            {errors.address && (
              <p className="text-danger-dark mt-2 text-sm">
                {errors.address.message}
              </p>
            )}
          </div>
        </SectionTitle>
        <SectionTitle title="توضیحات">
          <div className="w-full">
            <Input
              {...register("description", { required: true })}
              id="description"
              placeholder="در صورتی که برای سفارش خود توضیحاتی دارید ..."
              type="text"
            />
          </div>
          {errors.description && (
            <p className="text-danger-dark mt-2 text-sm">
              {errors.description.message}
            </p>
          )}
        </SectionTitle>
      </div>
      <div className="lg:col-span-2 col-span-1">
        <SectionTitle title="روش پرداخت" isFirstComponent>
          <div className="bg-gray35 w-full py-4 px-4 rounded-lg flex gap-2 items-center">
            <div className="flex gap-3 items-center">
              <input
                id="paymentMethod"
                defaultChecked
                type="radio"
                className="custom-radio"
                {...register("paymentMethod", { required: true })}
              />
              <p className="font-semibold text-base mb-1">پرداخت اینترنتی</p>
            </div>
            <p className="font-normal text-xs opacity-50">
              پرداخت آنلاین با تمامی کارت‌های بانکی
            </p>
          </div>
          {errors.paymentMethod && (
            <p className="text-danger-dark mt-2 text-sm">
              {errors.paymentMethod.message}
            </p>
          )}
        </SectionTitle>
        <SectionTitle title="روش ارسال">
          <div className="bg-gray35 w-full py-4 px-4 rounded-lg flex justify-between items-center">
            <div className="flex gap-3 items-center">
              <input
                id="shippingMethodStandard"
                value="standard"
                // checked
                type="radio"
                {...register("shippingMethod", { required: true })}
                className="custom-radio"
              />
              <div>
                <p className="font-semibold text-base mb-1">ارسال پست پیشتاز</p>
                <p className="font-normal text-sm opacity-50">
                  ارسال در۲ الی۴ روز کاری
                </p>
              </div>
            </div>
            <p className="text-green16">
              <span>{shippingCost.standard}</span> تومان
            </p>
          </div>
          <div className="bg-gray35 w-full py-4 px-4 rounded-lg flex justify-between items-center">
            <div className="flex gap-3 items-center">
              <input
                id="shippingMethodExpress"
                type="radio"
                value="express"
                {...register("shippingMethod", { required: true })}
                className="custom-radio"
              />
              <div>
                <p className="font-semibold text-base mb-1">ارسال فوری</p>
                <p className="font-normal text-sm opacity-50">
                  ارسال تا ۲۴ ساعت کاری
                </p>
              </div>
            </div>
            <p className="text-green16">
              <span>{shippingCost.express}</span> تومان
            </p>
          </div>
          {errors.shippingMethod && (
            <p className="text-danger-dark mt-2 text-sm">
              {errors.shippingMethod.message}
            </p>
          )}
        </SectionTitle>
        <div className="mt-10 flex flex-col gap-6">
          <p className="font-LightPinar text-2xl font-semibold">
            جمع کل سبد خرید
          </p>
          <div className="border-b border-white flex justify-between items-center py-4">
            <p className="font-normal text-lg opacity-50">جمع جز :</p>
            <p className="font-semibold text-xl">۱,۲۰۰,۰۰۰ تومان</p>
          </div>
          <div className="border-b border-white flex justify-between items-center pb-4">
            <p className="font-normal text-lg opacity-50">هزینه ارسال :</p>
            <p className="font-semibold text-xl">
              <span>{shippingCost.express}</span> تومان
            </p>
          </div>
          <div className=" flex justify-between items-center">
            <p>جمع کل :</p>
            <p className="font-semibold text-xl">۱,۳۰۰,۰۰۰ تومان</p>
          </div>
        </div>
        <div className="mt-20">
          <Button type="submit" appearance="primary" shouldFitContainer>
            ارسال
          </Button>
        </div>
      </div>
    </form>
  );
};
export default CheckoutIndex;
