import { forwardRef } from "react";

interface InputInterface {
  id: string;
  name: string;
  type: "text" | "number" | "email";
  isRequired?: boolean;
  placeholder: string;
  icon?: React.ComponentType;
  inverted?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputInterface>(
  (
    {
      id,
      name,
      type,
      isRequired = false,
      placeholder,
      icon: Icon,
      inverted = false,
      ...props
    },
    ref
  ) => {
    return (
      <div className="w-full relative">
        {Icon && (
          <div className="absolute top-[50%] -translate-y-1/2 right-4 opacity-50">
            <Icon />
          </div>
        )}
        <input
          id={id}
          name={name}
          type={type}
          className={`outline-none ${
            inverted ? "bg-[#1A1A1A]" : "bg-gray35"
          } rounded-lg py-3 w-full ${Icon ? "px-12" : "px-4"}
        `}
          placeholder={placeholder + `${isRequired ? "*" : ""}`}
          ref={ref}
          required={isRequired}
          {...props}
        />
      </div>
    );
  }
);

export default Input;
