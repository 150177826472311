const Instagram = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_422_946)">
        <path
          d="M22 0H10C4.478 0 0 4.478 0 10V22C0 27.522 4.478 32 10 32H22C27.522 32 32 27.522 32 22V10C32 4.478 27.522 0 22 0ZM29 22C29 25.86 25.86 29 22 29H10C6.14 29 3 25.86 3 22V10C3 6.14 6.14 3 10 3H22C25.86 3 29 6.14 29 10V22Z"
          fill="#177FE6"
        />
        <path
          d="M16 8C11.582 8 8 11.582 8 16C8 20.418 11.582 24 16 24C20.418 24 24 20.418 24 16C24 11.582 20.418 8 16 8ZM16 21C13.244 21 11 18.756 11 16C11 13.242 13.244 11 16 11C18.756 11 21 13.242 21 16C21 18.756 18.756 21 16 21Z"
          fill="#177FE6"
        />
        <path
          d="M24.5999 8.46598C25.1887 8.46598 25.6659 7.98872 25.6659 7.39998C25.6659 6.81125 25.1887 6.33398 24.5999 6.33398C24.0112 6.33398 23.5339 6.81125 23.5339 7.39998C23.5339 7.98872 24.0112 8.46598 24.5999 8.46598Z"
          fill="#177FE6"
        />
      </g>
      <defs>
        <clipPath id="clip0_422_946">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Instagram;
