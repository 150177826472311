import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

// Contexts
import { useOrder } from "../../../contexts/OrderProvider";

// API
import { getCoverImage } from "../api";

// Components
import Button from "../../../common/Button";
import MusicBox from "../components/Lyrics/MusicBox";
import Tag from "../../../common/Tag";
import PrintPreview from "../components/Preview/PrintPreview";
import ColorSelector from "../components/Preview/ColorSelector";
import ProgressBar from "../../../common/Progress";

// Assets
import WhiteTshirt from "../../../assets/white-tshirt.png";
import BlackTshirt from "../../../assets/black-tshirt.png";
import PreviewInfoModal from "../components/Preview/InfoModal";
import PreOrderModal from "../components/Preview/PreOrder/Modal";

const PreviewPage = () => {
  const { coverRequestId } = useParams();
  const navigate = useNavigate();
  const { song, source, coverRequest, refetchCoverRequest } = useOrder();

  const [color, setColor] = useState<"dark" | "light">("light");
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false);
  const [isFailed, setIsFailed] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);
  const [isPreOrderModalOpen, setIsPreOrderModalOpen] =
    useState<boolean>(false);

  const handleColor = () => {
    setColor(color === "light" ? "dark" : "light");
  };

  const handleCloseInfoModal = () => {
    setIsInfoModalOpen(false);
  };

  const handleClosePreOrderModal = () => {
    setIsPreOrderModalOpen(false);
  };

  useEffect(() => {
    if (coverRequest?.status === "success") {
      setIsLoaded(true);
      return;
    }

    const handleRefetch = async () => {
      try {
        if (coverRequest?.status === "pending") {
          refetchCoverRequest();
        }

        if (coverRequest?.status === "success") {
          clearInterval(intervalId);
          setIsLoaded(true);
        }

        if (coverRequest?.status === "failed") {
          clearInterval(intervalId);
          setIsLoaded(false);
          setIsFailed(true);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const intervalId = setInterval(handleRefetch, 5000);

    return () => clearInterval(intervalId);
  }, [coverRequest, refetchCoverRequest]);

  useEffect(() => {
    if (!isLoaded) return;

    const loadImage = async () => {
      try {
        setIsLoadingImage(true);
        const stream = await getCoverImage(coverRequestId, color);

        if (stream instanceof Blob) {
          const url = URL.createObjectURL(stream);
          setImageUrl(url);
        }
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          if (error.response?.status === 404) {
            setIsLoaded(false);
            refetchCoverRequest();
          }
        }

        console.error(error);
      } finally {
        setIsLoadingImage(false);
      }
    };

    loadImage();
  }, [isLoaded, coverRequestId, color, refetchCoverRequest]);

  return (
    <>
      <Helmet>
        <title>{song?.name} - پیش‌نمایش</title>
      </Helmet>

      {isFailed ? (
        <div className="flex flex-col justify-center items-center">
          <div className="mb-5 text-center">
            <h2 className="text-2xl font-bold font-Pinar mb-3">اوپس!</h2>
            <p>متاسفانه ایجاد کاور اختصاصی شما با خطا مواجه شد.</p>
          </div>

          <Button
            appearance="secondary"
            onClick={() =>
              navigate(
                `/order/lyrics/${source?.keyword}/${coverRequest?.music_title}/${coverRequest?.id}`
              )
            }
          >
            بازگشت به صفحه نخست
          </Button>
        </div>
      ) : (
        <>
          <div className="border rounded-2xl w-full h-[600px] md:h-auto md:aspect-square bg-gray-500 relative mb-12">
            <img
              src={color === "light" ? WhiteTshirt : BlackTshirt}
              alt="product-preview"
              className="w-full h-full object-cover rounded-2xl"
            />
            {isLoaded ? (
              <>
                <PrintPreview
                  imageUrl={imageUrl}
                  text={coverRequest?.selected_text}
                  color={color}
                  isLoading={isLoadingImage}
                />

                <ColorSelector color={color} handleChange={handleColor} />
              </>
            ) : (
              <>
                <div
                  className={`w-full backdrop-blur-md h-full flex flex-col gap-8 items-center justify-center absolute top-0 left-0 rounded-2xl ${
                    color === "light" ? "text-black" : "text-white"
                  }`}
                >
                  <ProgressBar
                    duration={40000}
                    className={"font-Pinar text-3xl"}
                  />
                  <p className="w-9/12 font-Pinar text-xl shadow-2xl text-center">
                    طرح منحصر به فردت رو قراره اینجا ببینی
                  </p>
                </div>
              </>
            )}
          </div>

          {isLoaded && (
            <>
              <div className="flex justify-between items-start lg:flex-row flex-col gap-4 mb-12">
                <p className="font-Pinar font-extrabold lg:text-2xl text-xl">
                  این طرح، خاص توئه!
                </p>

                <Tag onClick={() => setIsInfoModalOpen(true)} hasIcon>
                  این طرح چطور منحصر به من ایجاد شده؟
                </Tag>
              </div>

              <div className="font-Pinar font-extrabold text-base my-12">
                <Button
                  appearance="primary"
                  onClick={() => setIsPreOrderModalOpen(true)}
                  shouldFitContainer
                >
                  می‌خوام سفارشش بدم!
                </Button>
              </div>
            </>
          )}

          <MusicBox song={song} source={source} />

          <PreviewInfoModal
            isOpen={isInfoModalOpen}
            onClose={handleCloseInfoModal}
          />

          <PreOrderModal
            isOpen={isPreOrderModalOpen}
            onClose={handleClosePreOrderModal}
            selectedColor={color}
            coverRequest={coverRequest}
            song={song}
          />
        </>
      )}
    </>
  );
};

export default PreviewPage;
