import { ReactNode } from "react";
import MoreInfo from "./icons/MoreInfo";

const Tag = ({
  children,
  hasIcon,
  onClick,
}: {
  children: ReactNode;
  hasIcon?: boolean;
  onClick?: () => void;
}) => {
  return (
    <button
      onClick={onClick}
      className="flex gap-3 items-center bg-info-dark bg-opacity-30 py-2 px-3 rounded-full text-nowrap w-fit font-semibold text-xs"
    >
      {children}
      {hasIcon && <MoreInfo />}
    </button>
  );
};

export default Tag;
