const Copyright = () => {
  return (
    <>
      <div className="border-t border-gray35">
        <p className="font-light text-sm text-center py-6 px-3">
          کلیه حقوق محصولات و محتوای سایت متعلق به فروشگاه آنلاین نیکی‌شاپ است و
          هر گونه کپی برداری از محتوا و محصولات سایت پیگرد قانونی دارد
        </p>
      </div>
    </>
  );
};

export default Copyright;
