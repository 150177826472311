// Icons Import
import Call from "./icons/Call";
import Close from "./icons/Close";
import Telegram from "./icons/Telegram";
import Whatsapp from "./icons/Whatsapp";

const ContactItem = [
  {
    title: "شماره تماس ",
    contact: "۰۹۳۶۶۹۵۵۵۸۸",
    icon: <Call />,
    bgClass: "bg-gray71",
    to: "tel:09366955588",
  },
  {
    title: "واتساپ",
    contact: "۰۹۳۶۶۹۵۵۵۸۸",
    icon: <Whatsapp />,
    bgClass: "bg-green2c",
    to: "https://wa.me/+989366955588",
  },
  {
    title: "تلگرام",
    contact: "۰۹۳۶۶۹۵۵۵۸۸",
    icon: <Telegram />,
    bgClass: "bg-blue70",
    to: "https://t.me/nikkyshop_support",
  },
];

const SupportPopUp = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full backdrop-blur-md">
          <div className="w-[300px] h-[400px]">
            <div className="flex items-center justify-between p-4 bg-primary-dark rounded-t-[20px]">
              <p className="font-Pinar">پشتیبانی سایت</p>
              <button onClick={onClose}>
                <Close />
              </button>
            </div>
            <div className="p-4 bg-gray35 gap-4 flex flex-col rounded-b-[20px]">
              <p className="font-normal text-base opacity-70">
                راه‌های ارتباطی پشتیبانی در ساعات کاری
              </p>
              {ContactItem.map((item, index) => (
                <a key={index} href={item?.to} target="_blank" rel="noreferrer">
                  <div
                    className={`flex items-center justify-between ${item.bgClass} rounded-[10px] p-4`}
                  >
                    <div className="font-semibold text-xs opacity-50">
                      {item.title}
                    </div>
                    <div className="flex gap-3 items-start justify-between">
                      <p className="font-semibold text-base">{item.contact}</p>
                      <div>{item.icon}</div>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SupportPopUp;
