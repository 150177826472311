import { useState } from "react";
import Cart from "../../../assets/icons/Cart.svg";
import Tshirt from "../../../assets/Tshirt.png";
import Button from "../../../common/Button";
import { useLocation } from "react-router-dom";

interface ProductDetails {
  name: string;
  gender: string;
  size: string;
  color: string;
  number: number;
  price: string;
  Tshirt: any;
}

const productDetails: ProductDetails[] = [
  {
    name: "تیشرت تمام نخ",
    gender: "زنانه",
    size: "M",
    color: "سفید",
    number: 2,
    price: "۱,۰۰۰,۰۰۰ ",
    Tshirt: Tshirt,
  },
  {
    name: "تیشرت تمام نخ",
    gender: "زنانه",
    size: "M",
    color: "سفید",
    number: 2,
    price: "۱,۰۰۰,۰۰۰ ",
    Tshirt: Tshirt,
  },
  {
    name: "تیشرت تمام نخ",
    gender: "زنانه",
    size: "M",
    color: "سفید",
    number: 2,
    price: "۱,۰۰۰,۰۰۰ ",
    Tshirt: Tshirt,
  },
];
const ShoppingCart = () => {
  const [count, setCount] = useState<any>(2);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const location = useLocation();
  return (
    <div className="pr-4">
      <div
        className="w-12 h-12 relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img src={Cart} alt="Shopping cart" className="w-full h-full" />
        {isHovered && location.pathname === "/order/add" && (
          <div
            className={`absolute top-[70%] left-[70%] bg-gray35 w-80 rounded-2xl p-5 transition-all duration-300 transform lg:flex lg:flex-col hidden`}
          >
            {productDetails.map((product: ProductDetails, index: number) => (
              <div
                key={index}
                className={`flex mb-4 ${
                  index > 0
                    ? "border-t border-white border-opacity-50 pt-4"
                    : ""
                }`}
              >
                <div className="w-[30%]  flex flex-col gap-4">
                  <img src={product.Tshirt} alt="shirt" />
                  <div className="flex">
                    <div
                      onClick={() => setCount(count - 1)}
                      className="flex items-center justify-center cursor-pointer border-white rounded-lg w-8 h-8 font-bold text-2xl "
                    >
                      -
                    </div>
                    <div className="flex items-center justify-center w-8 h-8 text-xl">
                      {count}
                    </div>
                    <div
                      onClick={() => setCount(count + 1)}
                      className="flex items-center justify-center cursor-pointer border-white rounded-lg w-8 h-8 font-bold text-2xl "
                    >
                      +
                    </div>
                  </div>
                </div>
                <div className="w-[70%] px-2">
                  <p className="font-extrabold text-xl font-LightPinar">
                    {product.name}
                  </p>
                  <div className="border-white border-b border-opacity-30 mt-6 mb-4"></div>
                  <div className="flex justify-between items-center text-lg">
                    <p className="text-sm">{product.gender}</p>
                    <p className="text-sm">
                      <span>سایز : </span>
                      {product.size}
                    </p>
                    <p className="text-sm">
                      <span>رنگ : </span>
                      {product.color}
                    </p>
                  </div>
                  <p className="text-success-dark mt-4 text-end">
                    {product.price}
                    <span>تومان</span>
                  </p>
                </div>
              </div>
            ))}
            <div className="w-full flex gap-4 mt-6">
              <Button shouldFitContainer appearance="secondary">
                ادامه خرید
              </Button>
              <Button shouldFitContainer appearance="primary">
                تسویه حساب
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShoppingCart;
