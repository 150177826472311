import { ReactNode } from "react";

interface Props {
  appearance: "danger" | "info" | "success";
  children: ReactNode;
}

const styling = {
  danger: "bg-danger-dark",
  info: "bg-info-dark",
  success: "bg-success-dark",
};

const Alert = ({ appearance, children }: Props) => {
  return (
    <div
      className={`${styling?.[appearance]} bg-opacity-10 rounded-lg p-3 mb-3`}
    >
      {children}
    </div>
  );
};

export default Alert;
