// Components
import Modal from "../../../../common/Modal";
import Button from "../../../../common/Button";

const PreviewInfoModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className="mb-5">
          <h2 className="text-xl font-Pinar mb-5">
            این طرح چطور منحصر به من ایجاد شده؟
          </h2>
          یکی از مهم‌ترین ویژگی‌های هوش مصنوعی توانایی در ساخت تصاویر منحصر به
          فرده. پس اگر از هوش مصنوعی درخواست کنید که یه تصویر توصیف شده رو برای
          شما ایجاد کنه حتی اگر توصیف شما از تصویر یکسان باشه باز هم خروجی هوش
          مصنوعی همیشه منحصر به فرد و خاص خواهد بود. برای همین روی این ویژگی هوش
          مصنوعی تکیه می‌کنیم تا تضمین کنیم حتی اگر آهنگ مورد علاقه شما انتخاب
          هزاران نفر دیگه هم باشه تصویر طرحی که برای شما ساخته می‌شه همیشه منحصر
          به فرد خواهد بود.
        </div>

        <Button appearance="secondary" onClick={onClose} shouldFitContainer>
          متوجه شدم!
        </Button>
      </Modal>
    </>
  );
};

export default PreviewInfoModal;
