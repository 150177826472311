import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

// Components
import Button from "../../../common/Button";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>یافت نشد</title>
      </Helmet>
      
      <div className="w-full flex flex-col justify-center items-center py-20">
        <div className="mb-4">صفحه یا آهنگ مورد نظر شما یافت نشد.</div>
        <Button appearance="primary" onClick={() => navigate("/")}>
          بازگشت به صفحه اصلی
        </Button>
      </div>
    </>
  );
};

export default NotFoundPage;
