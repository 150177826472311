import { useState } from "react";
import { Outlet } from "react-router-dom";

// Components
import Navbar from "./components/Navbar/index";
import Copyright from "./components/Copyright";
import SupportPopUp from "./components/SupportPopUp";
import ScrollToTop from "../common/ScrollToTop";

// Icon
import SupportIcon from "./components/SupportPopUp/icons/SupportIcon";

const MainLayout = () => {
  const [isSupportOpen, setIsSupportOpen] = useState<boolean>(false);

  const toggleSupportModal = () => {
    setIsSupportOpen(!isSupportOpen);
  };

  return (
    <>
      <ScrollToTop />

      <Navbar />
      <div className="grid grid-cols-12 mt-2 relative">
        <div className="lg:col-start-3 col-start-2 lg:col-span-8 col-span-10">
          <div
            className="sticky top-[85%] mr-auto cursor-pointer z-40 w-fit"
            onClick={toggleSupportModal}
          >
            <SupportIcon />
          </div>

          <Outlet />
        </div>
      </div>
      <Copyright />

      <SupportPopUp isOpen={isSupportOpen} onClose={toggleSupportModal} />
    </>
  );
};

export default MainLayout;
