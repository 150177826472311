import React from "react";

const Cart = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.8843 21.7531L26.8592 13.4936C26.2346 10.5464 24.467 9.3335 22.7819 9.3335H9.24228C7.53362 9.3335 5.70713 10.4615 5.17685 13.4936L4.13987 21.7531C3.29144 27.8174 6.41416 29.3335 10.4914 29.3335H21.5446C25.6101 29.3335 28.6385 27.1382 27.8843 21.7531ZM12.1293 16.1983C11.4785 16.1983 10.9509 15.6552 10.9509 14.9854C10.9509 14.3156 11.4785 13.7726 12.1293 13.7726C12.7801 13.7726 13.3077 14.3156 13.3077 14.9854C13.3077 15.6552 12.7801 16.1983 12.1293 16.1983ZM18.6694 14.9854C18.6694 15.6552 19.197 16.1983 19.8478 16.1983C20.4986 16.1983 21.0261 15.6552 21.0261 14.9854C21.0261 14.3156 20.4986 13.7726 19.8478 13.7726C19.197 13.7726 18.6694 14.3156 18.6694 14.9854Z"
        fill="#FEFEFE"
      />
      <path
        opacity="0.4"
        d="M22.6317 9.03251C22.6359 9.13593 22.6161 9.23892 22.5737 9.33341H20.6575C20.6204 9.23734 20.6008 9.13545 20.5995 9.03251C20.5995 6.47585 18.5197 4.40326 15.9543 4.40326C13.3888 4.40326 11.309 6.47585 11.309 9.03251C11.3266 9.13205 11.3266 9.23388 11.309 9.33341H9.34644C9.32886 9.23388 9.32886 9.13205 9.34644 9.03251C9.49555 5.47463 12.4332 2.66675 16.0065 2.66675C19.5798 2.66675 22.5175 5.47463 22.6666 9.03251H22.6317Z"
        fill="#FEFEFE"
      />
    </svg>
  );
};

export default Cart;
