// Context
import { useOrder } from "../../../contexts/OrderProvider";

// Components
import MusicBox from "../components/Lyrics/MusicBox";
import LyricsSelector from "../components/Lyrics/LyricsSelector";
import { Helmet } from "react-helmet-async";

const LyricsPage = () => {
  const { source, song, slug } = useOrder();

  return (
    <>
      <Helmet>
        <title>{song?.name} - انتخاب متن</title>
      </Helmet>

      <div>
        <h2 className="text-3xl font-extrabold mb-8 font-Pinar">
          تی‌شرت خودت رو بساز!
        </h2>

        {source && song && (
          <>
            <MusicBox source={source} song={song} />

            <div className="mt-16">
              <LyricsSelector song={song} sourceID={source.id} slug={slug} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default LyricsPage;
