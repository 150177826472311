import { useState } from "react";

// Components
import Button from "../../../common/Button";

// Image and Icon
import SelectIcon from "../assets/icons/SelectIcon";
import InfoIcon from "../assets/icons/InfoIcon";
import sizeForMen from "../../../assets/sizeForMen.png";
import sizeForWomen from "../../../assets/sizeForWomen.png";
import Close from "../../../layouts/components/SupportPopUp/icons/Close";

const OrderPage = () => {
  const [gender, setGender] = useState<boolean>(true);
  const [color, setColor] = useState<"black" | "white">("black");
  const [size, setSize] = useState<"s" | "m" | "l" | "xl">("s");
  const [sizeHelper, setSizeHelper] = useState<boolean>(true);

  const hanldeGender = () => {
    setGender(!gender);
  };
  const hanldeColor = () => {
    setColor(color === "black" ? "white" : "black");
  };

  const handleSize = (selectedSize: "s" | "m" | "l" | "xl") => {
    setSize(selectedSize);
  };

  return (
    <>
      {sizeHelper === true ? (
        <div className="w-full h-full absolute top-0 right-0 z-50 backdrop-blur-lg flex justify-center">
          <div className="w-full h-screen flex justify-center items-center flex-col-reverse gap-8 ">
            {gender === false ? (
              <img src={sizeForMen} alt="" />
            ) : (
              <img src={sizeForWomen} alt="" />
            )}
            <button onClick={() => setSizeHelper(!sizeHelper)}>
              <Close />
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className="border rounded-2xl w-full bg-gray-500 relative">
        <div>
          {/* <img
            src={Mockup}
            width={"100%"}
            height={"100%"}
            alt="product-preview"
            className="relative rounded-2xl"
          /> */}
        </div>
        {/* <ImageHandler GeneretedCover="GeneretedCover" /> */}
        <>
          <div className="absolute lg:top-6 lg:left-6 top-3 left-3 bg-gray-600 p-2 rounded-xl flex items-center justify-center lg:gap-4 lg:h-14 gap-2  w-32 lg:w-auto">
            <div
              onClick={hanldeGender}
              className={`rounded-lg font-semibold text-lg cursor-pointer w-16 flex justify-center items-center h-full ${
                gender === false
                  ? "bg-white text-black border-[#EEEDED] border"
                  : "bg-transparent border-[#EEEDED] border text-white"
              }`}
            >
              مردانه
            </div>
            <div
              onClick={hanldeGender}
              className={`rounded-lg font-semibold text-lg cursor-pointer w-16 flex justify-center items-center h-full ${
                gender === true
                  ? "bg-white text-black border-[#EEEDED] border"
                  : "bg-transparent border-[#EEEDED] border text-white"
              }`}
            >
              زنانه
            </div>
          </div>
          <div className="absolute lg:top-6 lg:right-6 top-3 right-3 bg-gray-600 p-2 rounded-xl flex items-center justify-center lg:gap-4 lg:h-14 gap-2 w-20 lg:w-auto">
            <div
              onClick={hanldeColor}
              className={`rounded-lg cursor-pointer bg-black`}
            >
              {color === "black" ? (
                <SelectIcon color="white" />
              ) : (
                <div className="w-8 h-8 bg-transparent"></div>
              )}
            </div>
            <div
              onClick={hanldeColor}
              className={`rounded-lg cursor-pointer bg-white`}
            >
              {color === "white" ? (
                <SelectIcon color="black" />
              ) : (
                <div className="w-8 h-8 bg-transparent"></div>
              )}
            </div>
          </div>
        </>
      </div>
      <div className="my-8 flex items-center justify-between ">
        <div className="flex justify-start items-start flex-col gap-4">
          <p className="font-LightPinar text-base font-medium opacity-50">
            طرح اختصاصی
          </p>
          <p className="font-Pinar text-3xl font-extrabold">تیشرت تمام نخ</p>
        </div>
        <div className="flex justify-start items-start flex-col gap-4">
          <p className="font-LightPinar text-base font-medium opacity-50">
            قیمت
          </p>
          <p className="font-Pinar text-3xl font-extrabold text-success-dark">
            ۱,۲۰۰,۰۰۰ تومان
          </p>
        </div>
      </div>
      <p className="font-light text-lg text-justify opacity-60">
        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده
        از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و
        سطرآنچنان که لازم است .
      </p>
      <div className="flex items-center justify-between my-8">
        <div className="flex flex-col gap-8">
          <div className="flex justify-between items-center gap-8">
            <div className="font-LightPinar font-bold text-2xl">سایز :</div>
            <div className="flex items-center justify-center gap-1">
              <p
                onClick={() => setSizeHelper(!sizeHelper)}
                className="font-normal text-sm opacity-50 cursor-pointer"
              >
                راهنمای انتخاب سایز
              </p>
              <InfoIcon />
            </div>
          </div>
          <div className="font-LightPinar text-base font-normal flex gap-4">
            <div
              onClick={() => handleSize("xl")}
              className={`bg-gray35 px-4 py-2 rounded-lg cursor-pointer ${
                size === "xl" ? "bg-white text-black font-black" : ""
              }`}
            >
              XL
            </div>
            <div
              onClick={() => handleSize("l")}
              className={`bg-gray35 px-4 py-2 rounded-lg cursor-pointer ${
                size === "l" ? "bg-white text-black font-black" : ""
              }`}
            >
              L
            </div>
            <div
              onClick={() => handleSize("m")}
              className={`bg-gray35 px-4 py-2 rounded-lg cursor-pointer ${
                size === "m" ? "bg-white text-black font-black" : ""
              }`}
            >
              M
            </div>
            <div
              onClick={() => handleSize("s")}
              className={`bg-gray35 px-4 py-2 rounded-lg cursor-pointer ${
                size === "s" ? "bg-white text-black font-black" : ""
              }`}
            >
              S
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-8 px-8">
          <div className="font-LightPinar font-bold text-2xl ">رنگ :</div>
          <div className="flex items-center justify-between gap-2">
            <div className="bg-black rounded-md px-5 py-3 border-8 border-gray35 cursor-pointer"></div>
            <div className="bg-white rounded-md px-5 py-3 border-8 border-gray35 cursor-pointer"></div>
          </div>
        </div>
      </div>
      <div className="font-Pinar font-extrabold text-base my-12">
        <Button appearance="primary" shouldFitContainer>
          افزودن به سبد خرید
        </Button>
      </div>
    </>
  );
};

export default OrderPage;
