import * as yup from "yup";

export const schema = yup
  .object()
  .shape({
    name: yup.string().required("نام خود را وارد کنید"),
    lastName: yup.string().required("نام خانوادگی خود را وارد کنید"),
    phoneNumber: yup
      .string()
      .matches(/^\d{11}$/, "شماره تلفن باید حداقل 11 رقم باشد")
      .required("شماره تلفن را وارد کنید"),
    email: yup
      .string()
      .email("ایمیل معتبر نمیباشد")
      .required("ایمیل خود را وارد کنید"),
    state: yup
      .string()
      .required("استان خود را وارد کنید")
      .notOneOf([""], "استان خود را انتخاب کنید"),
    city: yup.string().required("شهر خود را وارد کنید"),
    postalCode: yup.string().required("کد پستی خود را وارد کنید"),
    homePhoneNumber: yup.string(),
    address: yup.string().required("آدرس خود را وارد کنید"),
    description: yup.string(),
    paymentMethod: yup.string().required("نحوه پرداخت خود را وارد کنید"),
    shippingMethod: yup.string().required("نحوه ارسال خود را وارد کنید"),
  })
  .required();
