import React from "react";

const Pen = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M22.6568 21.271H15.0644C14.3237 21.271 13.7212 21.883 13.7212 22.6355C13.7212 23.3894 14.3237 24 15.0644 24H22.6568C23.3975 24 24 23.3894 24 22.6355C24 21.883 23.3975 21.271 22.6568 21.271Z"
        fill="#FEFEFE"
      />
      <path
        d="M9.74536 5.20514L16.9398 11.0186C17.1134 11.1576 17.143 11.4127 17.0076 11.5905L8.47832 22.7043C7.94216 23.3908 7.15202 23.7792 6.30545 23.7936L1.64928 23.8509C1.40095 23.8538 1.18366 23.6818 1.12723 23.4352L0.0690063 18.8344C-0.114418 17.9887 0.0690063 17.1144 0.605171 16.4408L9.17675 5.27393C9.31502 5.09477 9.57041 5.06324 9.74536 5.20514Z"
        fill="#FEFEFE"
      />
      <path
        opacity="0.4"
        d="M20.1607 7.55392L18.7737 9.28534C18.6341 9.46164 18.3829 9.4903 18.2094 9.34984C16.5233 7.98534 12.2057 4.4838 11.0078 3.51345C10.8329 3.37012 10.8089 3.115 10.95 2.93727L12.2876 1.27608C13.501 -0.286218 15.6174 -0.429547 17.3247 0.932085L19.2859 2.49438C20.0902 3.12503 20.6263 3.95634 20.8098 4.83065C21.0214 5.7924 20.7956 6.73694 20.1607 7.55392Z"
        fill="#FEFEFE"
      />
    </svg>
  );
};

export default Pen;
