import { Link, useNavigate } from "react-router-dom";

// Interfaces
import { CoverRequest } from "../../types/types";

// Components
import Modal from "../../../../common/Modal";
import Button from "../../../../common/Button";

const LimitModal = ({
  isOpen,
  onClose,
  requests,
}: {
  isOpen: boolean;
  onClose: () => void;
  requests: CoverRequest[];
}) => {
  const navigate = useNavigate();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="mb-5">
        <div className="flex flex-col justify-center items-center font-LightPinar mb-10">
          <h2 className="text-xl font-semibold">
            متاسفانه تعداد مجاز ساخت کاور امروز برای شما به پایان رسیده است
          </h2>
          <p className="font-medium">
            در صورت تمایل می توانید از بین کاورهایی که قبلا ایجاد کرده‌اید
            انتخاب نمائید:
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {requests?.map((request, key) => (
            <Link to={`/order/preview/${request?.id}`}>
              <div
                key={key}
                className="flex flex-col justify-center items-center text-center bg-[#1A1A1A] rounded-lg p-10"
              >
                <h5 className="text-lg font-bold">
                  {request?.music_persian_title}
                </h5>
                <span className="text-md font-medium">
                  {request?.source?.name}
                </span>
                <small className="text-sm">{request?.created_at}</small>
              </div>
            </Link>
          ))}
        </div>
      </div>

      <Button
        appearance="secondary"
        onClick={() => navigate("/")}
        shouldFitContainer
      >
        بازگشت به صفحه نخست
      </Button>
    </Modal>
  );
};

export default LimitModal;
