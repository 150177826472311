const LeftArrow = () => {
  return (
    <div>
      <svg
        width="18"
        height="12"
        viewBox="0 0 19 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.165 5.99339L10.1634 5.6393C10.1513 4.23482 10.0666 2.98174 9.92061 2.18763C9.92061 2.17331 9.76134 1.38572 9.65988 1.12355C9.50061 0.74463 9.21262 0.422985 8.85153 0.219203C8.56244 0.0738021 8.25917 0 7.94172 0C7.69218 0.011566 7.28064 0.13714 6.98692 0.242421L6.74283 0.335965C5.12612 0.978153 2.03561 3.07656 0.85199 4.35983L0.764728 4.44953L0.375268 4.86984C0.129817 5.19148 0 5.58473 0 6.00771C0 6.38663 0.115635 6.76556 0.346905 7.07178C0.41614 7.17099 0.527664 7.29827 0.626929 7.40581L1.006 7.80261C2.31046 9.12428 5.13485 10.9782 6.59883 11.5924C6.59883 11.6057 7.50864 11.9857 7.94172 12H7.99954C8.66389 12 9.28461 11.6211 9.60207 11.0086C9.68879 10.8412 9.77197 10.5132 9.83524 10.2252L9.94897 9.68129C10.0788 8.80668 10.165 7.46503 10.165 5.99339ZM16.4967 7.51834C17.3269 7.51834 18 6.8387 18 6.00044C18 5.16218 17.3269 4.48254 16.4967 4.48254L12.7975 4.80969C12.1463 4.80969 11.6183 5.34173 11.6183 6.00044C11.6183 6.65805 12.1463 7.19119 12.7975 7.19119L16.4967 7.51834Z"
          fill="#FEFEFE"
        />
      </svg>
    </div>
  );
};

export default LeftArrow;
