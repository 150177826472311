// Components
import LandingPageTitle from "../../../../common/LandingPageTitle";
import SpinnerLoading from "../../../../common/SpinnerLoading";

// Hooks
import useSources from "../../hooks/useSources";

// Types
import { MusicSource } from "../../../Ordering/types/types";

// Icon
import LinkIcon from "../../assets/icons/LinkIcon";

const MusicSourcesSection = () => {
  const { data: sources, isLoading } = useSources();

  return (
    <div className="flex flex-col items-center pt-16 gap-8" id="sources">
      <LandingPageTitle children="منابع متن آهنگ" />
      <p className="font-normal text-base opacity-60 text-center w-full md:w-1/2">
        ما برای اینکه بتونیم بازه وسیعی از قطعه‌های موسیقی رو پوشش بدیم در حال
        حاضر داریم با وب‌سایت‌های پخش آهنگ زیر همکاری می‌کنیم. لطفا به یکی از
        سایت‌های زیر برید، آهنگ مورد علاقتون رو پیدا کنید و روی بنر ما کلیک
        کنید.
      </p>

      {isLoading ? (
        <SpinnerLoading />
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 w-full">
          {Array.isArray(sources) && (
            <>
              {sources?.map((source: MusicSource, index: any) => (
                <a
                  key={index}
                  href={source?.website}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="bg-landingBg-blue flex items-center justify-between p-4 rounded-[10px] self-start">
                    <div className="flex gap-2 items-center justify-center">
                      {source?.logo !== null && (
                        <img
                          src={source?.logo}
                          alt={source?.name}
                          width={38}
                          height={38}
                        />
                      )}

                      <div className="flex justify-center items-center">
                        <p className="font-semibold text-xl">{source?.name}</p>
                        <p className="font-light text-sm opacity-70">
                          {source?.description}
                        </p>
                      </div>
                    </div>

                    <div>
                      <LinkIcon />
                    </div>
                  </div>
                </a>
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default MusicSourcesSection;
