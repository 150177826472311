import { ReactNode } from "react";

const LandingPageTitle = ({ children }: { children: ReactNode }) => {
  return (
    <h2
      className="font-Pinar font-extrabold text-[28px] leading-[58px]
    after:w-[32px] after:h-[5px] after:rounded-full after:bg-info-dark after:top-[100%] after:left-[50%] after:absolute after:-translate-x-1/2 after:-translate-y-1/2 after:mt-2 relative"
    >
      {children}
    </h2>
  );
};

export default LandingPageTitle;
