import { cloneElement } from "react";
import { useLocation } from "react-router-dom";

// Assets
import LyricsIcon from "../../assets/icons/Lyrics";
import PreviewIcon from "../../assets/icons/Preview";
import OrderIcon from "../../assets/icons/Order";

const steps = [
  {
    label: "انتخاب متن",
    icon: <LyricsIcon color="" />,
    path: "/order/lyrics",
  },
  {
    label: "پیش‌نمایش",
    icon: <PreviewIcon color="" />,
    path: "/order/preview",
  },

  {
    label: "سفارش",
    icon: <OrderIcon color="" />,
    path: "/order/add",
  },
];

const Step = ({
  label,
  icon,
  isActive,
  isFirst,
}: {
  label: string;
  icon: any;
  isActive: boolean;
  isFirst: boolean;
}) => {
  return (
    <div className={`flex ${!isFirst ? "flex-1" : ""}`}>
      {!isFirst && (
        <div
          className={`grow h-[1px] w-full border mx-4 mt-6 ${
            isActive
              ? "border-white"
              : "border-dashed border-[#FEFEFE] border-opacity-30"
          }`}
        ></div>
      )}
      <div
        className={`flex flex-col items-center text-center ${
          isActive
            ? "text-[#FFFFFF] font-bold"
            : "text-[#FEFEFE] text-opacity-30"
        }`}
      >
        <div className="bg-[#2E2E35] rounded-lg w-12 h-12 flex justify-center items-center mb-2.5 text-white">
          {cloneElement(icon, {
            color: isActive ? "#FFFFFF" : "#FEFEFE",
            opacity: isActive ? 1 : 0.3,
          })}
        </div>
        <span>{label}</span>
      </div>
    </div>
  );
};

const Progress = () => {
  const { pathname } = useLocation();
  const activeStepIndex = steps.findIndex((step) =>
    pathname.includes(step.path)
  );

  return (
    <div className="flex font-LightPinar">
      {steps.map((step, index) => (
        <Step
          key={index}
          label={step?.label}
          icon={step?.icon}
          isActive={index <= activeStepIndex}
          isFirst={index === 0}
        />
      ))}
    </div>
  );
};

export default Progress;
