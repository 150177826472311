// Components
import Modal from "../../../../common/Modal";
import Button from "../../../../common/Button";

const LyricsInfoModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className="mb-5">
          <h2 className="text-xl font-Pinar mb-5">
            چرا باید متن مورد علاقم را هایلایت کنم؟
          </h2>
          یکی از چیزهایی که آدم‌ها رو متمایز میکنه علایق آدم‌هاست. در حالی که
          همه آدم‌ها می‌تونن به یک آهنگ یکسان گوش بدن، اما هر کسی با یک قسمت به‌
          خصوصی از آهنگ ارتباط می‌گیره. برای همین ما از شما می‌خوایم که قسمتی از
          آهنگ که برای شما محبوبه رو انتخاب کنید. تا ما با استفاده از قسمت
          انتخاب شده طرحی که به علاقه شما نزدیک‌تر هست رو بسازیم. ما از متن کامل
          آهنگ برای منتقل کردن احساسات آهنگ استفاده می‌کنیم اما متن انتخاب شده
          تمرکز ما برای ساخت طرح خواهد بود.
        </div>

        <Button appearance="secondary" onClick={onClose} shouldFitContainer>
          متوجه شدم!
        </Button>
      </Modal>
    </>
  );
};

export default LyricsInfoModal;
