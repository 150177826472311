const ShoppingCartUnSuccessful = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.7913 72.3333L44.333 60.875"
        stroke="#FF0000"
        strokeWidth="6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.6663 61L44.208 72.4583"
        stroke="#FF0000"
        strokeWidth="6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.7083 8.33325L21.625 23.4583"
        stroke="#FF0000"
        strokeWidth="6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.292 8.33325L78.3753 23.4583"
        stroke="#FF0000"
        strokeWidth="6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33301 32.7083C8.33301 25 12.458 24.375 17.583 24.375H82.4163C87.5413 24.375 91.6663 25 91.6663 32.7083C91.6663 41.6667 87.5413 41.0417 82.4163 41.0417H17.583C12.458 41.0417 8.33301 41.6667 8.33301 32.7083Z"
        stroke="#FF0000"
        strokeWidth="6"
      />
      <path
        d="M14.583 41.6667L20.458 77.6667C21.7913 85.7501 24.9997 91.6667 36.9163 91.6667H62.0413C74.9997 91.6667 76.9163 86.0001 78.4163 78.1667L85.4163 41.6667"
        stroke="#FF0000"
        strokeWidth="6"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ShoppingCartUnSuccessful;
