import React from "react";

const Person = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1579_2"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="12"
        width="16"
        height="8"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 12.4961H15.8399V19.8701H0V12.4961Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1579_2)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.921 13.9961C3.66 13.9961 1.5 14.7281 1.5 16.1731C1.5 17.6311 3.66 18.3701 7.921 18.3701C12.181 18.3701 14.34 17.6381 14.34 16.1931C14.34 14.7351 12.181 13.9961 7.921 13.9961ZM7.921 19.8701C5.962 19.8701 0 19.8701 0 16.1731C0 12.8771 4.521 12.4961 7.921 12.4961C9.88 12.4961 15.84 12.4961 15.84 16.1931C15.84 19.4891 11.32 19.8701 7.921 19.8701Z"
          fill="#FEFEFE"
        />
      </g>
      <mask
        id="mask1_1579_2"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="2"
        y="0"
        width="12"
        height="11"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.61035 0H13.2304V10.6186H2.61035V0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_1579_2)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.92138 1.42751C5.78038 1.42751 4.03838 3.16851 4.03838 5.30951C4.03138 7.44351 5.76038 9.18351 7.89238 9.19151L7.92138 9.90551V9.19151C10.0614 9.19151 11.8024 7.44951 11.8024 5.30951C11.8024 3.16851 10.0614 1.42751 7.92138 1.42751ZM7.92138 10.6185H7.88938C4.96738 10.6095 2.60038 8.22651 2.61038 5.30651C2.61038 2.38151 4.99238 -0.000488281 7.92138 -0.000488281C10.8494 -0.000488281 13.2304 2.38151 13.2304 5.30951C13.2304 8.23751 10.8494 10.6185 7.92138 10.6185Z"
          fill="#FEFEFE"
        />
      </g>
    </svg>
  );
};

export default Person;
