import { useState } from "react";

// Hooks
import useQuestions from "../../hooks/useQuestions";

// Interfaces
import { Question } from "../../types";

// Components
import LandingPageTitle from "../../../../common/LandingPageTitle";
import SpinnerLoading from "../../../../common/SpinnerLoading";

// Icons
import DownArrow from "../../assets/icons/DownArrow";

const FAQsection = () => {
  const [toggledQuestion, setToggledQuestion] = useState<number | null>(null);

  const { data: questions, isLoading } = useQuestions();

  const toggle = (index: number) => {
    setToggledQuestion(toggledQuestion === index ? null : index);
  };

  return (
    <>
      <div className="flex flex-col items-center mt-16 gap-8" id="faq">
        <LandingPageTitle children="سوالات متداول" />
        {isLoading ? (
          <SpinnerLoading />
        ) : (
          <>
            {Array.isArray(questions) && (
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 w-full">
                {questions?.map((question: Question, index: any) => (
                  <div
                    key={index}
                    onClick={() => toggle(index)}
                    className={`border border-white p-4 rounded-[10px] cursor-pointer self-start`}
                  >
                    <div className="flex justify-between items-center">
                      <div className="font-bold">{question?.title}</div>
                      <div
                        className={
                          toggledQuestion === index ? "rotate-180" : ""
                        }
                      >
                        <DownArrow />
                      </div>
                    </div>
                    <div
                      className={
                        toggledQuestion === index ? "block mt-2" : "hidden"
                      }
                    >
                      {question?.answer}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default FAQsection;
