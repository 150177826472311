import { Routes, Route } from "react-router-dom";

// Layouts
import MainLayout from "../layouts/MainLayout";
import OrderingLayout from "../features/Ordering/layout";
import CheckoutLayout from "../features/Purchase/layout";

// Pages
import IndexPage from "../features/Index/views/Index";
import NotFoundPage from "../features/Index/views/404";
import PreviewPage from "../features/Ordering/views/Preview";
import OrderPage from "../features/Ordering/views/Order";

// Ordering
import LyricsPage from "../features/Ordering/views/Lyrics";

// checkout
import CheckoutIndex from "../features/Purchase/views";
import SuccessCheckout from "../features/Purchase/views/components/SuccessPurchase";
import UnsuccessfulCheckout from "../features/Purchase/views/components/UnsuccessfulPurchase";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<IndexPage />} />
        <Route path="*" element={<NotFoundPage />} />

        <Route path="order" element={<OrderingLayout />}>
          <Route path="lyrics/:source/:slug/:coverRequestId?" element={<LyricsPage />} />
          <Route path="preview/:coverRequestId" element={<PreviewPage />} />
          <Route path="add" element={<OrderPage />} />
        </Route>

        <Route path="checkout" element={<CheckoutLayout />}>
          <Route index element={<CheckoutIndex />} />
          <Route path="success-checkout" element={<SuccessCheckout />} />
          <Route path="unsuccess-checkout" element={<UnsuccessfulCheckout />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
