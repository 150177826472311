import { useQuery } from "@tanstack/react-query";

// API
import { getQuestions } from "../api";

const useQuestions = () => {
  return useQuery({
    queryKey: ["questions"],
    queryFn: getQuestions,
    retry: 2,
  });
};

export default useQuestions;
