import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import Fingerprint2 from "fingerprintjs2";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

interface ContextValue {
  fingerprint: string | null;
  captchaToken: string | null | undefined;
  refetchCaptchaToken: () => void;
}

const AppContext = createContext<ContextValue | null>(null);

export function useApp() {
  return useContext(AppContext) as ContextValue;
}

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [fingerprint, setFingerprint] = useState<string | null>(null);
  const [captchaToken, setCaptchaToken] = useState<string | null | undefined>(
    null
  );

  const getBrowserFingerprint = (): Promise<string> => {
    return new Promise((resolve) => {
      Fingerprint2.get((components) => {
        const filteredComponents = components.filter(component => {
          return component.key !== 'ip' && component.key !== 'networkInformation';
        });
  
        const values = filteredComponents.map((component) => component.value);
        const fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
        resolve(fingerprint);
      });
    });
  };

  const refetchCaptchaToken = async () => {
    if (!executeRecaptcha) return;
    try {
      const token = await executeRecaptcha("ACTION");
      setCaptchaToken(token);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (fingerprint) return;
    const handleFingerprint = async () => {
      try {
        const res = await getBrowserFingerprint();
        setFingerprint(res);
        setIsLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    handleFingerprint();
  }, [fingerprint]);

  useEffect(() => {
    if (!executeRecaptcha || captchaToken) return;

    const fetchToken = async () => {
      try {
        const token = await executeRecaptcha("ACTION");
        setCaptchaToken(token);
      } catch (e) {
        console.error(e);
      }
    };

    fetchToken();
  }, [captchaToken, executeRecaptcha]);

  const values: ContextValue = {
    fingerprint,
    captchaToken,
    refetchCaptchaToken,
  };

  return (
    <AppContext.Provider value={values}>
      {!isLoading && children}
    </AppContext.Provider>
  );
};
