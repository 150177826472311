import axios, { AxiosInstance } from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const addResponseInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    (res) => {
      if (res && res.data) return res.data;
      return res;
    },
    (e) => {
      return Promise.reject(e);
    }
  );
};

const addCSRFInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    (res) => res,
    async (e) => {
      const config = e.config;

      if (e?.response?.status !== 419 || config._retry) {
        return Promise.reject(e);
      }

      config._retry = true;

      try {
        await csrf();
        return instance(config);
      } catch (error) {
        return Promise.reject(error);
      }
    }
  );
};

const createClient = () => {
  return axios.create({
    timeout: 60000,
    baseURL: BASE_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

const axiosClient = createClient();
addResponseInterceptor(axiosClient);
addCSRFInterceptor(axiosClient);

const csrf = () => axiosClient.get("/sanctum/csrf-cookie");

export default axiosClient;
