const OrderIcon = ({
  color,
  opacity = 1,
}: {
  color: string;
  opacity?: number;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color}
      opacity={opacity}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.32759 8.34749L6.93059 15.5195C6.97459 16.0715 7.42559 16.4855 7.97659 16.4855H7.98059H18.8916H18.8936C19.4146 16.4855 19.8596 16.0975 19.9336 15.5825L20.8836 9.02349C20.9056 8.86749 20.8666 8.71149 20.7716 8.58549C20.6776 8.45849 20.5396 8.37649 20.3836 8.35449C20.1746 8.36249 11.5016 8.35049 6.32759 8.34749ZM7.97459 17.9855C6.65759 17.9855 5.54259 16.9575 5.43559 15.6425L4.51959 4.74849L3.01259 4.48849C2.60359 4.41649 2.33059 4.02949 2.40059 3.62049C2.47259 3.21149 2.86759 2.94549 3.26759 3.00949L5.34759 3.36949C5.68259 3.42849 5.93759 3.70649 5.96659 4.04649L6.20159 6.84749C20.4776 6.85349 20.5236 6.86049 20.5926 6.86849C21.1496 6.94949 21.6396 7.24049 21.9736 7.68849C22.3076 8.13549 22.4476 8.68649 22.3676 9.23849L21.4186 15.7965C21.2396 17.0445 20.1556 17.9855 18.8956 17.9855H18.8906H7.98259H7.97459Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2874 12.0437H14.5154C14.1004 12.0437 13.7654 11.7077 13.7654 11.2937C13.7654 10.8797 14.1004 10.5437 14.5154 10.5437H17.2874C17.7014 10.5437 18.0374 10.8797 18.0374 11.2937C18.0374 11.7077 17.7014 12.0437 17.2874 12.0437Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.54451 20.7019C7.84551 20.7019 8.08851 20.9449 8.08851 21.2459C8.08851 21.5469 7.84551 21.7909 7.54451 21.7909C7.24251 21.7909 6.99951 21.5469 6.99951 21.2459C6.99951 20.9449 7.24251 20.7019 7.54451 20.7019Z"
        fill="currentColor"
      />
      <mask
        id="mask0_108_525"
        maskUnits="userSpaceOnUse"
        x="6"
        y="20"
        width="3"
        height="2"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.99878 21.2457C6.99878 21.5477 7.24178 21.7917 7.54478 21.7917C7.84578 21.7917 8.08878 21.5477 8.08878 21.2457C8.08878 20.9447 7.84578 20.7017 7.54478 20.7017C7.24178 20.7017 6.99878 20.9447 6.99878 21.2457Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_108_525)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.99976 26.7907H13.0888V15.7017H1.99976V26.7907Z"
          fill="currentColor"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.54351 21.0409C7.43051 21.0409 7.33851 21.1329 7.33851 21.2459C7.33851 21.4729 7.74951 21.4729 7.74951 21.2459C7.74951 21.1329 7.65651 21.0409 7.54351 21.0409ZM7.54351 22.5409C6.82951 22.5409 6.24951 21.9599 6.24951 21.2459C6.24951 20.5319 6.82951 19.9519 7.54351 19.9519C8.25751 19.9519 8.83851 20.5319 8.83851 21.2459C8.83851 21.9599 8.25751 22.5409 7.54351 22.5409Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8248 20.7019C19.1258 20.7019 19.3698 20.9449 19.3698 21.2459C19.3698 21.5469 19.1258 21.7909 18.8248 21.7909C18.5228 21.7909 18.2798 21.5469 18.2798 21.2459C18.2798 20.9449 18.5228 20.7019 18.8248 20.7019Z"
        fill="currentColor"
      />
      <mask
        id="mask1_108_525"
        maskUnits="userSpaceOnUse"
        x="18"
        y="20"
        width="2"
        height="2"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.2798 21.2457C18.2798 21.5477 18.5228 21.7917 18.8248 21.7917C19.1248 21.7917 19.3698 21.5477 19.3698 21.2457C19.3698 20.9447 19.1248 20.7017 18.8248 20.7017C18.5228 20.7017 18.2798 20.9447 18.2798 21.2457Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_108_525)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2798 26.7907H24.3698V15.7017H13.2798V26.7907Z"
          fill="currentColor"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8238 21.0409C18.7118 21.0409 18.6198 21.1329 18.6198 21.2459C18.6208 21.4749 19.0308 21.4729 19.0298 21.2459C19.0298 21.1329 18.9368 21.0409 18.8238 21.0409ZM18.8238 22.5409C18.1098 22.5409 17.5298 21.9599 17.5298 21.2459C17.5298 20.5319 18.1098 19.9519 18.8238 19.9519C19.5388 19.9519 20.1198 20.5319 20.1198 21.2459C20.1198 21.9599 19.5388 22.5409 18.8238 22.5409Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default OrderIcon;
