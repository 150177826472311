const Calling = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0101 4.87351C20.6937 4.34875 18.9498 2.44377 17.7071 2.50127C17.3353 2.53311 17.0067 2.75698 16.7396 3.01783H16.7386C16.1265 3.61756 14.3703 5.88094 14.2717 6.35745C14.0283 7.52611 15.4219 8.19978 14.9957 9.37769C13.9091 12.0364 12.0368 13.9086 9.3768 14.994C8.1998 15.4212 7.5261 14.0287 6.3573 14.2711C5.8808 14.3707 3.6161 16.1257 3.0174 16.7388C2.7575 17.0048 2.5316 17.3344 2.5008 17.7062C2.4546 19.0155 4.4778 20.7829 4.8722 21.0088C5.8027 21.6753 7.0167 21.663 8.4966 20.975C12.6263 19.2569 19.2261 12.782 20.9762 8.49657C21.6458 7.02496 21.691 5.8029 21.0101 4.87351Z"
        stroke="#086AFB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.43457 2.75C5.73257 3.161 2.80957 6.081 2.39357 9.782"
        stroke="#086AFB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.43457 6.29297C7.66457 6.63797 6.28157 8.02097 5.93657 9.79097"
        stroke="#086AFB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Calling;
