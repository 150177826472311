import React from "react";

const Eye = () => {
  return (
    <svg
      width="28"
      height="22"
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.79683 10.9999C8.79683 13.8444 11.1253 16.1587 14.0001 16.1587C16.8619 16.1587 19.1903 13.8444 19.1903 10.9999C19.1903 8.14254 16.8619 5.8282 14.0001 5.8282C11.1253 5.8282 8.79683 8.14254 8.79683 10.9999ZM21.6489 3.06133C23.9253 4.81972 25.8635 7.39264 27.2554 10.612C27.3594 10.8577 27.3594 11.1421 27.2554 11.3749C24.4716 17.8137 19.5155 21.6666 14.0001 21.6666H13.9871C8.48463 21.6666 3.52854 17.8137 0.744797 11.3749C0.640732 11.1421 0.640732 10.8577 0.744797 10.612C3.52854 4.17325 8.48463 0.333252 13.9871 0.333252H14.0001C16.7578 0.333252 19.3724 1.29002 21.6489 3.06133ZM14.0016 14.2165C15.7838 14.2165 17.2407 12.7684 17.2407 10.9971C17.2407 9.21283 15.7838 7.76475 14.0016 7.76475C13.8455 7.76475 13.6894 7.77768 13.5464 7.80354C13.4943 9.22576 12.3236 10.3635 10.8797 10.3635H10.8146C10.7756 10.5704 10.7496 10.7773 10.7496 10.9971C10.7496 12.7684 12.2065 14.2165 14.0016 14.2165Z"
        fill="#FEFEFE"
      />
    </svg>
  );
};

export default Eye;
