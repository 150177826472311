import { useEffect, useState } from "react";

const ProgressBar = ({
  duration,
  className,
}: {
  duration: number;
  className: string;
}) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const interval = duration / 100;
    const timer = setInterval(() => {
      setPercentage((prev) => {
        if (prev < 100) {
          return prev + 1;
        } else {
          clearInterval(timer);
          return prev;
        }
      });
    }, interval);

    return () => clearInterval(timer);
  }, [duration]);

  return <div className={className}>%{percentage}</div>;
};

export default ProgressBar;
