import { useQuery } from "@tanstack/react-query";

// API
import { getSources } from "../api";

const useSources = () => {
  return useQuery({
    queryKey: ["sources"],
    queryFn: getSources,
    retry: 2,
  });
};

export default useSources;
