import { ReactNode } from "react";

const Modal = ({
  isOpen,
  onClose,
  children,
}: {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}) => {
  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full backdrop-blur-md">
          <div className="w-11/12 lg:w-1/2 max-h-[80vh] overflow-y-auto rounded-lg">
            <div className="p-4 bg-gray35 ">
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
