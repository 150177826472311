import { useState, useMemo, useRef, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Context
import { useApp } from "../../../../contexts/AppProvider";

// Interfaces
import { CoverRequest, Song } from "../../types/types";

// API
import { requestCover } from "../../api";

// Components
import Tag from "../../../../common/Tag";
import Button from "../../../../common/Button";
import SpinnerLoading from "../../../../common/SpinnerLoading";
import LimitModal from "./LimitModal";
import LyricsInfoModal from "./InfoModal";
import Alert from "../../../../common/Alert";

const maxChar = 50;

const LyricsSelector = ({
  song,
  sourceID,
  slug,
}: {
  song: Song;
  sourceID: any;
  slug: string | undefined;
}) => {
  const {
    fingerprint: finger_print,
    captchaToken,
    refetchCaptchaToken,
  } = useApp();

  const navigate = useNavigate();
  const selectorRef = useRef<HTMLDivElement>(null);
  const [highlightedText, setHighlightedText] = useState<string>("");
  const [highlightRanges, setHighlightRanges] = useState<
    Array<{ start: number; end: number; text: string }>
  >([]);
  const [highlightCount, setHighlightCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLimitModalOpen, setIsLimitModalOpen] = useState<boolean>(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);
  const [existingRequests, setExistingRequests] = useState<CoverRequest[]>([]);
  const [error, setError] = useState<string | undefined>(undefined);

  const getRangeOffsets = useCallback((range: Range) => {
    const preSelectionRange = range.cloneRange();
    preSelectionRange.selectNodeContents(selectorRef.current!);
    preSelectionRange.setEnd(range.startContainer, range.startOffset);
    const start = preSelectionRange.toString().length;
    const end = start + range.toString().length;
    return { start, end };
  }, []);

  const isRangeHighlighted = useCallback(
    (start: number, end: number) => {
      for (const range of highlightRanges) {
        if (start >= range.start && end <= range.end) {
          return true;
        }
      }
      return false;
    },
    [highlightRanges]
  );

  const extractTextFromSpan = useCallback((span: HTMLSpanElement): string => {
    const paragraphs = span.getElementsByTagName("p");

    if (paragraphs.length > 0) {
      const textParts: string[] = [];

      for (let i = 0; i < paragraphs.length; i++) {
        textParts.push(paragraphs[i].textContent || "");
      }

      return textParts.join("\n");
    } else {
      return span.textContent || "";
    }
  }, []);

  const removeOverlappingRanges = useCallback((start: number, end: number) => {
    setHighlightRanges((prev) => {
      const ranges = prev.filter(
        (range) =>
          !(range.start >= start && end >= range.end) ||
          (range.start === start && range.end === end)
      );

      const newHighlightedText = ranges.map((range) => range?.text).join("\n");
      setHighlightedText(newHighlightedText);
      setHighlightCount(newHighlightedText.length);

      return ranges;
    });
  }, []);

  const handleHighlight = useCallback(() => {
    const selection = window.getSelection();

    if (selection?.rangeCount) {
      const range = selection.getRangeAt(0);
      const selectedText = range.toString();

      if (selectedText) {
        const { start, end } = getRangeOffsets(range);

        const isStartInContainer = selectorRef.current?.contains(
          range.startContainer
        );
        const isEndInContainer = selectorRef.current?.contains(
          range.endContainer
        );

        if (isStartInContainer && isEndInContainer) {
          if (!isRangeHighlighted(start, end)) {
            const span = document.createElement("span");
            span.className = "text-info-dark font-bold";
            span.appendChild(range.extractContents());
            range.insertNode(span);

            const extracted = extractTextFromSpan(span);

            setHighlightedText((prev) =>
              prev ? prev + "\n" + extracted : extracted
            );
            setHighlightCount((prev) => prev + selectedText.length);
            setHighlightRanges((prev) => [
              ...prev,
              { start, end, text: extracted },
            ]);

            removeOverlappingRanges(start, end);
          }
        }
      }

      selection.removeAllRanges();
    }
  }, [
    isRangeHighlighted,
    getRangeOffsets,
    extractTextFromSpan,
    removeOverlappingRanges,
  ]);

  const resetHighlight = () => {
    setHighlightedText("");
    setHighlightCount(0);
    setHighlightRanges([]);
    if (selectorRef.current) {
      selectorRef.current.innerHTML = song.lyrics;
    }
  };

  const remainingCharacters = useMemo(() => {
    return Math.max(0, maxChar - highlightCount);
  }, [highlightCount]);

  const handleClick = async () => {
    if (!captchaToken) return;

    try {
      setError(undefined);
      setIsLoading(true);
      const response = await requestCover({
        finger_print,
        selected_text: highlightedText,
        music_text: song?.lyrics,
        source_id: sourceID,
        music_title: slug,
        music_persian_title: song?.name,
        status: "pending",
        "g-recaptcha-response": captchaToken,
      });

      const coverRequestId = response.data.id;
      refetchCaptchaToken();
      navigate(`/order/preview/${coverRequestId}`);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response?.status === 429) {
          const res = error.response?.data?.data;

          setExistingRequests(res);
          setIsLimitModalOpen(true);
        } else if (error.response?.status === 503) {
          setError(
            "محدودیت روزانه نیکی‌شاپ به پایان رسیده است. لطفا فردا تلاش کنید."
          );
        } else {
          setError("خطایی در پیش‌نمایش. لطفا با پشتیبانی در تماس باشید.");
          console.error(error);
        }
      }

      refetchCaptchaToken();
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseLimitModal = () => {
    setIsLimitModalOpen(false);
  };

  const handleCloseInfoModal = () => {
    setIsInfoModalOpen(false);
  };

  useEffect(() => {
    const element = selectorRef.current;

    const handleTouchEnd = () => handleHighlight();
    const handleMouseUp = () => handleHighlight();

    if (element) {
      element.addEventListener("touchend", handleTouchEnd);
      element.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      if (element) {
        element.removeEventListener("touchend", handleTouchEnd);
        element.removeEventListener("mouseup", handleMouseUp);
      }
    };
  }, [handleHighlight]);

  return (
    <>
      <div className="lg:flex justify-between items-end">
        <div className="flex lg:items-end lg:justify-between w-full flex-col items-start lg:flex-row gap-6 ">
          <div>
            <h5 className="text-xl font-bold w-full">
              لطفا بخش مورد علاقه خود را هایلایت کنید
            </h5>
            <span className="text-sm">
              متن هایلایت شده به رنگ آبی در خواهد آمد.
            </span>
          </div>

          <Tag onClick={() => setIsInfoModalOpen(true)}>
            چرا باید متن مورد علاقم را هایلایت کنم؟
          </Tag>
        </div>
      </div>
      <div className="justify-end lg:hidden flex my-4">
        {remainingCharacters} کاراکتر باقی‌مانده
      </div>
      <div className="lg:mt-8 mt-2 mb-6 relative">
        <div
          className="border rounded-lg h-72 overflow-y-auto p-8 scrollbar-thin scrollbar-thumb-rounded-lg scrollbar-thumb-[#2e2e35] scrollbar-track-transparent"
          ref={selectorRef}
          dangerouslySetInnerHTML={{ __html: song?.lyrics }}
        ></div>
        <div className="text-left absolute top-4 left-8 hidden lg:flex">
          {remainingCharacters} کاراکتر باقی‌مانده
        </div>
      </div>

      {error !== undefined && <Alert appearance="danger">{error}</Alert>}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <div>
          <Button
            customClass={`text-nowrap ${isLoading ? "!bg-gray35 h-12" : ""}`}
            onClick={handleClick}
            appearance="primary"
            disabled={
              highlightCount > maxChar ||
              isLoading === true ||
              !captchaToken ||
              !highlightedText
            }
            shouldFitContainer
          >
            {isLoading ? (
              <SpinnerLoading CustomClass="-translate-y-1" />
            ) : highlightCount > maxChar ? (
              `بیشتر از ${maxChar} کاراکتر انتخاب کرده‌اید`
            ) : !highlightedText ? (
              "متنی هایلایت نکرده‌اید"
            ) : (
              "پیش‌نمایش تی‌شرت"
            )}
          </Button>
        </div>
        <div>
          <Button
            appearance="secondary"
            onClick={resetHighlight}
            shouldFitContainer
          >
            لغو هایلایت
          </Button>
        </div>
      </div>

      <LimitModal
        isOpen={isLimitModalOpen}
        onClose={handleCloseLimitModal}
        requests={existingRequests}
      />

      <LyricsInfoModal
        isOpen={isInfoModalOpen}
        onClose={handleCloseInfoModal}
      />
    </>
  );
};

export default LyricsSelector;
