const LyricsIcon = ({
  color,
  opacity = 1,
}: {
  color: string;
  opacity?: number;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color}
      opacity={opacity}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.751 21.9397H13.498C13.084 21.9397 12.748 21.6037 12.748 21.1897C12.748 20.7757 13.084 20.4397 13.498 20.4397H20.751C21.165 20.4397 21.501 20.7757 21.501 21.1897C21.501 21.6037 21.165 21.9397 20.751 21.9397Z"
      />
      <mask
        id="mask0_108_518"
        maskUnits="userSpaceOnUse"
        x="2"
        y="3"
        width="18"
        height="19"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.00049 3.00024H19.1811V21.9398H2.00049V3.00024Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_108_518)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.1108 5.01689L3.69576 16.7919C3.52476 17.0059 3.46176 17.2819 3.52476 17.5469L4.20576 20.4319L7.24476 20.3939C7.53376 20.3909 7.80076 20.2619 7.97776 20.0419C11.1948 16.0169 17.3278 8.34289 17.5018 8.11789C17.6658 7.85189 17.7298 7.47589 17.6438 7.11389C17.5558 6.74289 17.3248 6.42789 16.9918 6.22689C16.9208 6.17789 15.2358 4.86989 15.1838 4.82889C14.5498 4.32089 13.6248 4.40889 13.1108 5.01689ZM3.61376 21.9399C3.26676 21.9399 2.96476 21.7019 2.88376 21.3629L2.06476 17.8919C1.89576 17.1729 2.06376 16.4309 2.52476 15.8549L11.9448 4.07289C11.9488 4.06889 11.9518 4.06389 11.9558 4.05989C12.9888 2.82489 14.8568 2.64289 16.1168 3.65389C16.1668 3.69289 17.8398 4.99289 17.8398 4.99289C18.4478 5.35489 18.9228 6.00189 19.1028 6.76789C19.2818 7.52589 19.1518 8.30789 18.7348 8.96889C18.7038 9.01789 18.6768 9.05989 9.14876 20.9799C8.68976 21.5519 8.00176 21.8849 7.26276 21.8939L3.62376 21.9399H3.61376Z"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2234 11.6849C16.0634 11.6849 15.9034 11.6339 15.7664 11.5299L10.3144 7.34187C9.98642 7.08987 9.92442 6.61987 10.1764 6.28987C10.4294 5.96187 10.8994 5.90087 11.2284 6.15287L16.6814 10.3399C17.0094 10.5919 17.0714 11.0629 16.8184 11.3919C16.6714 11.5839 16.4484 11.6849 16.2234 11.6849Z"
      />
    </svg>
  );
};

export default LyricsIcon;
