import React from "react";

const MoreInfo = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.41675 6.41663L1.63341 1.6333"
        stroke="#FEFEFE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.16655 3.96663V1.16663H3.96655"
        stroke="#FEFEFE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.58325 1.16663H8.74992C11.6666 1.16663 12.8333 2.33329 12.8333 5.24996V8.74996C12.8333 11.6666 11.6666 12.8333 8.74992 12.8333H5.24992C2.33325 12.8333 1.16658 11.6666 1.16658 8.74996V7.58329"
        stroke="#FEFEFE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MoreInfo;
