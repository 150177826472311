const SupportIcon = () => {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="40" cy="40" r="40" fill="#086AFB" />
      <path
        opacity="0.4"
        d="M43.2245 31.32C42.5898 31.2028 42.0069 31.6078 41.886 32.2274C41.7652 32.847 42.1715 33.4519 42.789 33.5731C44.648 33.9355 46.0834 35.3746 46.4473 37.2399V37.2413C46.5508 37.7782 47.0235 38.1686 47.568 38.1686C47.641 38.1686 47.714 38.162 47.7884 38.1486C48.4059 38.0247 48.8122 37.4211 48.6913 36.8002C48.1483 34.0142 46.0037 31.8623 43.2245 31.32Z"
        fill="#FEFEFE"
      />
      <path
        opacity="0.4"
        d="M43.1415 26.6773C42.844 26.6347 42.5452 26.7226 42.3076 26.9118C42.0632 27.1037 41.9105 27.3808 41.8773 27.6913C41.807 28.3189 42.2598 28.8865 42.8865 28.9571C47.2087 29.4394 50.5682 32.8064 51.0542 37.1421C51.1193 37.7231 51.6066 38.1614 52.1882 38.1614C52.232 38.1614 52.2745 38.1588 52.3184 38.1534C52.6224 38.1201 52.8933 37.9696 53.0845 37.7297C53.2744 37.4899 53.3607 37.1914 53.3262 36.8863C52.7207 31.4767 48.5339 27.2782 43.1415 26.6773Z"
        fill="#FEFEFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.7091 41.2965C44.0278 46.6138 45.2344 40.4623 48.6209 43.8464C51.8858 47.1103 53.7622 47.7643 49.6257 51.8996C49.1076 52.316 45.8156 57.3257 34.2462 45.7596C22.6754 34.192 27.6822 30.8966 28.0987 30.3786C32.2452 26.2318 32.8879 28.1192 36.1527 31.3831C39.5392 34.7687 33.3903 35.9792 38.7091 41.2965Z"
        fill="#FEFEFE"
      />
      <circle cx="40" cy="40" r="31.5" stroke="#FEFEFE" />
    </svg>
  );
};

export default SupportIcon;
