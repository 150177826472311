// Interfaces
import { Song } from "../../../types/types";

// Assets
import WhiteTshirt from "../../../../../assets/white-tshirt.png";
import BlackTshirt from "../../../../../assets/black-tshirt.png";
import SingerIcon from "../../../assets/icons/Singer";

const ProductCard = ({
  selectedColor,
  song,
}: {
  selectedColor: "light" | "dark";
  song: Song | null;
}) => {
  return (
    <div className="lg:flex bg-[#1A1A1A] rounded-lg p-6 mb-5">
      <div className="w-full lg:w-1/4 ml-6 mb-6 lg:mb-0">
        <img
          src={selectedColor === "light" ? WhiteTshirt : BlackTshirt}
          alt="تی‌شرت تمام نخ"
          className="rounded-lg w-full aspect-square"
        />
      </div>
      <div className="lg:w-3/4 w-full flex flex-col justify-between">
        <div className="lg:flex justify-between">
          <div className="mb-3 lg:mb-0">
            <span className="text-white text-opacity-50 font-medium">
              تی‌شرت تمام نخ
            </span>
            <h5 className="text-2xl font-bold mt-1">{song?.name}</h5>
          </div>
          <div>
            <span className="block text-white text-opacity-50 font-medium mb-2">
              زمان تحویل: تا ۳۰ روز دیگر
            </span>
          </div>
        </div>

        <div className="mt-4 lg:mt-8 lg:flex justify-between items-end">
          <div className="flex gap-2 mb-4 lg:mb-0">
            <div className="bg-gray35 w-fit p-2 rounded-full">
              <SingerIcon color="white" />
            </div>
            {song?.artists?.map((artist, index) => (
              <div
                key={index}
                className="bg-gray35 w-fit py-2 px-3 rounded-full"
              >
                {artist}
              </div>
            ))}
          </div>

          <div>
            <span className="text-green16 text-2xl font-bold">۸۰۰.۰۰۰</span>
            <h5 className="text-green16 line-through text-2xl font-bold">
              ۱.۲۰۰.۰۰۰ تومان
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
