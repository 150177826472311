import { useMemo } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

const NavbarItem = ({
  label,
  path,
  isActive,
}: {
  label: string;
  path: string;
  isActive: boolean;
}) => {
  const navigate = useNavigate();

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (path.startsWith("#")) {
      event.preventDefault();
      const element = document.getElementById(path.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigate(path);
    }
  };

  return (
    <li className={`ml-8 last:ml-0 ${isActive ? "font-bold" : ""}`}>
      <Link to={path} onClick={handleClick}>
        {label}
      </Link>
    </li>
  );
};

const NavbarMenu = () => {
  const { pathname } = useLocation();

  const navbarItems = useMemo(() => {
    return [
      {
        label: "صفحه نخست",
        path: "/",
        isActive: false,
      },
      {
        label: "راهنما",
        path: pathname === "/" ? "#help" : "/#help",
        isActive: false,
      },
      {
        label: "سوالات متداول",
        path: pathname === "/" ? "#faq" : "/#faq",
        isActive: false,
      },
    ];
  }, [pathname]);

  return (
    <div className="hidden md:block grow">
      <ul className="flex justify-center">
        {navbarItems.map((item, index) => (
          <NavbarItem
            key={index}
            label={item?.label}
            path={item?.path}
            isActive={item?.isActive}
          />
        ))}
      </ul>
    </div>
  );
};

export default NavbarMenu;
