import { ReactNode } from "react";

interface Props {
  appearance: "primary" | "secondary";
  shouldFitContainer?: boolean;
  onClick?: () => any;
  disabled?: boolean;
  customClass?: string;
  children: ReactNode;
  type?: "submit" | "reset" | "button" | undefined;
}

const styling = {
  primary: "bg-info-dark",
  secondary: "border",
};

const Button = ({
  appearance,
  shouldFitContainer = false,
  onClick,
  disabled = false,
  children,
  type,
  customClass,
}: Props) => {
  return (
    <button
      type={type}
      className={`p-2.5 rounded-lg ${customClass} ${styling?.[appearance]} 
        ${disabled ? "!bg-[#2d2d2d]" : ""}
        ${shouldFitContainer ? "w-full" : "w-fit"}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
