import React from "react";
import ShoppingCartUnSuccessful from "./Icons/ShoppingCartUnSuccessful";
import { Link } from "react-router-dom";
import Button from "../../../../common/Button";

const UnsuccessfulCheckout = () => {
  return (
    <div className="flex place-content-center w-full h-[calc(100vh-15.25rem)] font-Pinar">
      <div className="w-[450px] flex flex-col justify-center items-center lg:gap-4 gap-2 ">
        <div className="bg-[#2E151C] p-4 rounded-full">
          <ShoppingCartUnSuccessful />
        </div>
        <h3 className="font-extrabold lg:text-3xl text-xl text-center">
          پرداخت شما انجام نشد !
        </h3>
        <p className=" lg:text-2xl text-base text-center font-LightPinar">
          در صورت مشکل با پشتیبانی در تماس باشید
        </p>
        <Link to="/" className="w-full lg:mt-14 mt-28">
          <Button appearance="primary" shouldFitContainer={true}>
            بازگشت به سبد خرید
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default UnsuccessfulCheckout;
