// Components
import SelectIcon from "../../assets/icons/SelectIcon";

const ColorSelector = ({
  color,
  handleChange,
}: {
  color: string;
  handleChange: () => void;
}) => {
  return (
    <>
      <div className="absolute lg:top-6 lg:right-6 top-3 right-3 bg-gray-600 p-2 rounded-xl flex items-center justify-center lg:gap-4 lg:h-14 gap-2 w-20 lg:w-auto">
        <div
          onClick={handleChange}
          className={`rounded-lg cursor-pointer bg-black`}
        >
          {color === "dark" ? (
            <SelectIcon color="white" />
          ) : (
            <div className="w-8 h-8 bg-transparent"></div>
          )}
        </div>
        <div
          onClick={handleChange}
          className={`rounded-lg cursor-pointer bg-white`}
        >
          {color === "light" ? (
            <SelectIcon color="black" />
          ) : (
            <div className="w-8 h-8 bg-transparent"></div>
          )}
        </div>
      </div>
    </>
  );
};

export default ColorSelector;
