import React from "react";
import ShoppingCartSuccessful from "./Icons/ShoppingCartSuccessful";
import { Link } from "react-router-dom";
import Button from "../../../../common/Button";

const TrackingCode = 150817;

const SuccessCheckout = () => {
  return (
    <div className="flex place-content-center w-full h-[calc(100vh-15.25rem)] font-Pinar">
      <div className="w-[450px] flex flex-col justify-center items-center lg:gap-8 gap-4 ">
        <div className="bg-[#212B1E] p-4 rounded-full">
          <ShoppingCartSuccessful />
        </div>
        <h3 className="font-extrabold lg:text-3xl text-xl text-center">
          پرداخت شما با موفقیت انجام شد
        </h3>
        <p className=" lg:text-2xl text-base text-center font-LightPinar">
          وضعیت سفارش ثبت شده و از طریق ایمیل اطلاع‌رسانی خواهد شد.
        </p>
        <p className="font-bold lg:text-2xl text-lg rounded-[10px] px-8 py-2 border border-white font-LightPinar w-full flex place-content-center ">
          کـد سفـارش شمـا : <span>{TrackingCode}</span>
        </p>
        <Link to="/" className="w-full lg:mt-14 mt-28">
          <Button appearance="primary" shouldFitContainer={true}>
            بازگشت به صفحه اصلی
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default SuccessCheckout;
