// Interfaces
import { Song, MusicSource } from "../../types/types";

// Components
import SingerIcon from "../../assets/icons/Singer";
import ShortcutIcon from "../../assets/icons/Shortcut";

const MusicBox = ({
  source,
  song,
}: {
  source: MusicSource | null;
  song: Song | null;
}) => {
  return (
    <div className="lg:flex">
      <div className="w-full lg:w-1/4 ml-6 mb-6 lg:mb-0">
        <img
          src={song?.cover}
          alt={song?.name}
          className="rounded-lg w-full aspect-square"
        />
      </div>
      <div className="lg:w-3/4 w-full flex flex-col justify-between bg-[#2E2E35] rounded-lg p-6">
        <div className="lg:flex justify-between">
          <div className="mb-3 lg:mb-0">
            <span className="text-white text-opacity-50 font-medium">
              آهنگ انتخاب شده
            </span>
            <h5 className="text-2xl font-bold mt-1">{song?.name}</h5>
          </div>
          <div>
            <span className="block text-white text-opacity-50 font-medium mb-2">
              مرجع متن آهنگ
            </span>
            <a
              href={source?.website}
              target="_blank"
              rel="noreferrer"
              className="flex items-center"
            >
              <span className="ml-2 font-semibold">{source?.name}</span>
              <ShortcutIcon color="white" />
            </a>
          </div>
        </div>

        <div className="mt-8 flex gap-2">
          <div className="bg-[#17171F] w-fit p-2 rounded-full">
            <SingerIcon color="white" />
          </div>
          {song?.artists?.map((artist, index) => (
            <div
              key={index}
              className="bg-[#17171F] w-fit py-2 px-3 rounded-full"
            >
              {artist}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MusicBox;
