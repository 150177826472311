import React from "react";

const SelectIcon = ({ color }: { color: "white" | "black" }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9998 29.3334H19.9998C26.6665 29.3334 29.3332 26.6667 29.3332 20.0001V12.0001C29.3332 5.33341 26.6665 2.66675 19.9998 2.66675H11.9998C5.33317 2.66675 2.6665 5.33341 2.6665 12.0001V20.0001C2.6665 26.6667 5.33317 29.3334 11.9998 29.3334Z"
        stroke={`${color === "black" ? "#1A1A1A" : "#fff"}`}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3335 15.9999L14.1068 19.7732L21.6668 12.2266"
        stroke={`${color === "black" ? "#1A1A1A" : "#fff"}`}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SelectIcon;
