import axios from "axios";
import axiosClient from "../../../services/Request";

// Interfaces
import { MusicSource, CoverRequest, PreOrder } from "../types/types";

export const fetchSong = async (
  source: MusicSource,
  slug: string | undefined
) => {
  return await axios.get(`${source?.endpoint}/${slug}`);
};

export const requestCover = async (params: CoverRequest) => {
  return await axiosClient.post("/cover-request", params);
};

export const getCover = async (id: string | undefined) => {
  return await axiosClient.get(`/cover-request/${id}`);
};

export const getCoverImage = async (
  id: string | undefined,
  type: "light" | "dark"
) => {
  return await axiosClient.get(
    `/cover-request/${id}/stream-image/${type}_cover`,
    {
      responseType: "blob",
    }
  );
};

export const storePreOrder = async (params: PreOrder) => {
  return await axiosClient.post("/order", params);
};
