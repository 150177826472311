import React from "react";

const Call = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.7113 4.08785L20.6387 4.01204C18.7113 2.08444 17.4562 1.57351 16.1169 2.34362C15.7317 2.56515 15.3705 2.87523 14.8795 3.37401L13.3731 4.93603C12.5411 5.84393 12.3519 6.71478 12.6228 7.73465L12.6602 7.86967L12.7016 8.00392L12.903 8.59474C13.334 9.91972 13.154 10.6673 11.6205 12.2004C10.0258 13.7947 9.28144 13.9251 7.85426 13.4264L7.5999 13.338L7.29233 13.2381L7.15771 13.2007C6.07369 12.9115 5.15797 13.1423 4.18681 14.1128L2.97307 15.2852L2.61579 15.6366C2.21968 16.0405 1.95788 16.3606 1.7645 16.6989C0.999203 18.0377 1.51067 19.2921 3.49368 21.2677L3.68233 21.4589C3.97841 21.7442 4.25502 21.9486 4.66139 22.1416C5.34352 22.4655 6.1494 22.5876 7.08581 22.4573C9.39327 22.1364 12.3198 20.3158 16.0281 16.6085C16.33 16.3067 16.6191 16.0107 16.8958 15.7205L17.4325 15.1476C22.4624 9.68285 22.9061 6.32588 20.8448 4.2205L20.7113 4.08785ZM16.0936 4.28093C16.4194 3.95973 16.656 3.76394 16.8647 3.64396C17.3262 3.37856 17.8245 3.49074 18.7467 4.28448L19.0365 4.54334C19.087 4.59 19.1387 4.63843 19.1916 4.68868L19.5239 5.01231L19.5538 5.04972L19.7791 5.27603C20.3234 5.83204 20.5813 6.51064 20.3591 7.67767C19.9945 9.59269 18.3256 12.1905 14.9676 15.5477C11.4687 19.0456 8.80284 20.7041 6.87919 20.9716C5.75778 21.1276 5.18579 20.856 4.60246 20.2584L4.15762 19.8095C3.949 19.5912 3.77077 19.3933 3.62026 19.2126L3.41496 18.9539C2.8903 18.2551 2.84238 17.8358 3.06676 17.4433C3.15213 17.2939 3.27632 17.1302 3.45711 16.93L3.70235 16.671L3.84747 16.5258L5.37873 15.0472C5.89043 14.5753 6.24011 14.5084 6.77103 14.65L6.92431 14.6941L7.5586 14.9094C9.39871 15.5037 10.7262 15.2155 12.6811 13.2612C14.7057 11.237 14.9432 9.88537 14.2648 7.93917L14.2213 7.81411L14.1009 7.45153L14.0418 7.22043C13.9369 6.71746 14.0566 6.36677 14.5973 5.82601C14.6196 5.80375 14.6445 5.77866 14.6715 5.7512L16.0936 4.28093Z"
          fill="#FEFEFE"
        />
      </g>
    </svg>
  );
};

export default Call;
