import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

// Components
import IntroductionSection from "../components/Index/IntroductionSection";
import HowToWorkSection from "../components/Index/HowToWorkSection";
import MusicSourcesSection from "../components/Index/MusicSourcesSection";
import FAQsection from "../components/Index/FAQsection";
import ReviewSection from "../components/Index/ReviewSection";
import Footer from "../components/Footer";

const IndexPage = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (!hash) return;

    const element = document.getElementById(hash.substring(1));
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);

  return (
    <>
      <Helmet>
        <title>هارمونی استایل</title>
      </Helmet>
      
      <div>
        <IntroductionSection />
        <HowToWorkSection />
        <MusicSourcesSection />
        <FAQsection />
        <ReviewSection />
        <Footer />
      </div>
    </>
  );
};

export default IndexPage;
