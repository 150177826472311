import { Helmet } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Context
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { AppProvider } from "./contexts/AppProvider";

import AppRoutes from "./routes";

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
});

const siteKey = "6Lfgsh8qAAAAADg1TjwGb5lm_4vWt_11Yxr9mLpk";

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
        <AppProvider>
          <Helmet titleTemplate="نیکی‌شاپ - %s" />
          <AppRoutes />
        </AppProvider>
      </GoogleReCaptchaProvider>
    </QueryClientProvider>
  );
}

export default App;
