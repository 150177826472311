// Icon
import Cart from "../../assets/icons/Cart";
import Eye from "../../assets/icons/Eye";
import Pen from "../../assets/icons/Pen";
// Components
import LandingPageTitle from "../../../../common/LandingPageTitle";

const steps = [
  {
    icon: <Pen />,
    title: "۱. انتخاب متن موزیک",
    content:
      "اول یکی از منابع متن آهنگمون رو انتخاب  و آهنگ مورد علاقه‌ات رو پیدا می‌کنی. با کلیک روی کاور اختصاصی نیکی شاپ فرآیند شروع می‌شه و می‌تونی قسمتی از متن آهنگ که بیشتر از همه دوستش داری رو انتخاب کنی.",
  },
  {
    icon: <Eye />,
    title: "۲. پیش‌نمایش طرح",
    content:
      "در این مرحله بر اساس متن آهنگ و قسمت مورد علاقه‌ات که انتخابش کردی یه طرح منحصر به خودت ایجاد می‌کنیم.",
  },
  {
    icon: <Cart />,
    title: "۳. نهایی کردن سفارش",
    content:
      "حالا وقتشه که تصمیم بگیری! اگر از تماشا کردن تصویری که منحصر به تو خلق شده لذت بردی میتونی همیشه همراه خودت داشته باشیش! فقط کافیه دکمه ثبت سفارش رو بزنی تا یه تی‌شرت با طرح خلق شده برات ارسال بشه.",
  },
];

const HowToWorkSection = () => {
  return (
    <div
      id="help"
      className="flex flex-col items-center bg-gray35 rounded-[20px] p-10 mt-16 gap-14"
    >
      <LandingPageTitle children="نحوه کار" />
      <div className=" flex flex-col lg:flex-row gap-8">
        {steps.map((items, index) => (
          <div
            key={index}
            className=" flex-1 flex lg:flex-0 flex-col justify-start items-center gap-4 bg-primary-dark p-5 rounded-[20px]"
          >
            <div className="bg-info-dark p-3 rounded-[10px]">{items.icon}</div>
            <h3 className="font-Pinar font-extrabold text-base">
              {items.title}
            </h3>
            <p className="font-normal text-sm opacity-60 text-justify">
              {items.content}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowToWorkSection;
