import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

// API
import { fetchSong, getCover } from "../features/Ordering/api";

// Interfaces
import {
  Song,
  MusicSource,
  CoverRequest,
} from "../features/Ordering/types/types";

// Hooks
import useSources from "../features/Index/hooks/useSources";

// Components
import SpinnerLoading from "../common/SpinnerLoading";

interface ContextValue {
  song: Song | null;
  source: MusicSource | null;
  slug: string | undefined;
  coverRequest: CoverRequest | null;
  refetchCoverRequest: () => void;
}

const OrderContext = createContext<ContextValue | null>(null);

export function useOrder() {
  return useContext(OrderContext) as ContextValue;
}

export const OrderProvider = ({ children }: { children: ReactNode }) => {
  const { source: sourceKeyword, slug: songSlug, coverRequestId } = useParams();
  const { data: sources } = useSources();
  const navigate = useNavigate();

  const [song, setSong] = useState<Song | null>(null);
  const [source, setSource] = useState<MusicSource | null>(null);
  const [slug, setSlug] = useState<string | undefined>(undefined);
  const [coverRequest, setCoverRequest] = useState<CoverRequest | null>(null);

  const refetchCoverRequest = async () => {
    try {
      const { data } = await getCover(coverRequestId);

      setCoverRequest(data.cover_request);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (coverRequest || !coverRequestId) return;

    const fetchCoverRequest = async () => {
      try {
        const { data } = await getCover(coverRequestId);

        setCoverRequest(data.cover_request);
        setSource(data?.cover_request?.source);
        setSlug(data?.cover_request?.music_title);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          if (error.response?.status === 404) {
            navigate("/404", { replace: true });
          } else {
            console.error(error);
          }
        }
      }
    };

    fetchCoverRequest();
  }, [coverRequest, coverRequestId, navigate]);

  useEffect(() => {
    if (Array.isArray(sources) && sourceKeyword) {
      const res = sources.find(
        (item: MusicSource) => item?.keyword === sourceKeyword
      );

      if (res) {
        setSource(res);
        setSlug(songSlug);
      } else {
        navigate("/404", { replace: true });
      }
    }
  }, [sources, sourceKeyword, songSlug, navigate]);

  useEffect(() => {
    if (!source) return;

    const getSong = async () => {
      try {
        const { data } = await fetchSong(source, slug);
        setSong({
          ...data,
          name: data?.song,
        });
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          if (error.response?.status === 404) {
            navigate("/404", { replace: true });
          } else {
            console.error(error);
          }
        }
      }
    };
    getSong();
  }, [source, slug, navigate]);

  const isLoading = useMemo(() => {
    return !(source && song && slug);
  }, [source, song, slug]);

  const values: ContextValue = {
    song,
    source,
    slug,
    coverRequest,
    refetchCoverRequest,
  };

  return (
    <OrderContext.Provider value={values}>
      {isLoading ? (
        <>
          <div className="w-full mt-40 flex items-center justify-center flex-col font-Pinar">
            <SpinnerLoading />
            <span>لطفا صبر کنید.</span>
          </div>
        </>
      ) : (
        children
      )}
    </OrderContext.Provider>
  );
};
