import React from "react";

const Email = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.81443 11.2206C9.25693 11.2206 8.7011 11.0365 8.2361 10.6681L4.4986 7.65479C4.22943 7.43812 4.18777 7.04395 4.4036 6.77562C4.6211 6.50812 5.01443 6.46562 5.28277 6.68145L9.01693 9.69145C9.4861 10.0631 10.1469 10.0631 10.6194 9.68812L14.3161 6.68312C14.5844 6.46395 14.9778 6.50562 15.1961 6.77395C15.4136 7.04145 15.3728 7.43479 15.1053 7.65312L11.4019 10.6631C10.9336 11.0348 10.3736 11.2206 9.81443 11.2206Z"
          fill="#FEFEFE"
        />
        <mask
          id="mask0_167_663"
          // style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="1"
          width="19"
          height="17"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.833496 1.66663H18.7501V17.9166H0.833496V1.66663Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_167_663)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.69933 16.6666H13.8827C13.8843 16.665 13.891 16.6666 13.896 16.6666C14.8468 16.6666 15.6902 16.3266 16.3368 15.6808C17.0877 14.9333 17.5002 13.8591 17.5002 12.6566V6.93329C17.5002 4.60579 15.9785 2.91663 13.8827 2.91663H5.701C3.60516 2.91663 2.0835 4.60579 2.0835 6.93329V12.6566C2.0835 13.8591 2.49683 14.9333 3.24683 15.6808C3.8935 16.3266 4.73766 16.6666 5.68766 16.6666H5.69933ZM5.68516 17.9166C4.39933 17.9166 3.251 17.45 2.36433 16.5666C1.37683 15.5816 0.833496 14.1933 0.833496 12.6566V6.93329C0.833496 3.93079 2.926 1.66663 5.701 1.66663H13.8827C16.6577 1.66663 18.7502 3.93079 18.7502 6.93329V12.6566C18.7502 14.1933 18.2068 15.5816 17.2193 16.5666C16.3335 17.4491 15.1843 17.9166 13.896 17.9166H13.8827H5.701H5.68516Z"
            fill="#FEFEFE"
          />
        </g>
      </g>
    </svg>
  );
};

export default Email;
