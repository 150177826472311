import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import axios from "axios";

// Contexts
import { useApp } from "../../../../../contexts/AppProvider";

// API
import { storePreOrder } from "../../../api";

// Interfaces
import { CoverRequest } from "../../../types/types";

// Components
import Input from "../../../../../common/Form/Input";
import Button from "../../../../../common/Button";
import SpinnerLoading from "../../../../../common/SpinnerLoading";
import Alert from "../../../../../common/Alert";

// Assets
import Person from "../../../../Purchase/views/icons/Person";
import Call from "../../../../Purchase/views/icons/Call";

interface FormValues {
  first_name: string;
  last_name: string;
  phone: string;
}

type FieldName = "first_name" | "last_name" | "phone";

const PreOrderForm = ({
  coverRequest,
  selectedColor,
  handleClose,
  callback,
}: {
  coverRequest: CoverRequest | null;
  selectedColor: "light" | "dark";
  handleClose: () => void;
  callback: (res: any) => void;
}) => {
  const { fingerprint } = useApp();
  const {
    handleSubmit,
    formState: { errors },
    register,
    setError,
  } = useForm<FormValues>();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      setHasError(false);
      setIsSubmitting(true);

      const preOrder = {
        ...data,
        fingerprint: fingerprint,
        cover_request_id: coverRequest?.id,
        color: selectedColor,
      };

      const res = await storePreOrder(preOrder);

      callback(res);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.status === 422) {
          const fieldErrors = e.response.data?.errors;

          for (let [fieldName, fieldMessage] of Object.entries(fieldErrors)) {
            console.log(fieldName, fieldMessage);
            setError(fieldName as FieldName, {
              type: "api",
              message: "مقدار وارد شده صحیح نیست.",
            });
          }
        } else {
          setHasError(true);
        }
      }

      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-3 mb-10">
        <div>
          <Input
            id="first_name"
            type="text"
            placeholder="نام"
            icon={Person}
            isRequired
            inverted
            {...register("first_name")}
          />

          {errors.first_name && (
            <p className="text-danger-dark mt-2 text-sm">
              {errors.first_name.message}
            </p>
          )}
        </div>

        <div>
          <Input
            id="last_name"
            type="text"
            placeholder="نام خانوادگی"
            icon={Person}
            isRequired
            inverted
            {...register("last_name")}
          />

          {errors.last_name && (
            <p className="text-danger-dark mt-2 text-sm">
              {errors.last_name.message}
            </p>
          )}
        </div>

        <div>
          <Input
            id="phone"
            type="number"
            placeholder="شماره همراه"
            icon={Call}
            isRequired
            inverted
            {...register("phone")}
          />

          {errors.phone && (
            <p className="text-danger-dark mt-2 text-sm">
              {errors.phone.message}
            </p>
          )}
        </div>
      </div>

      {hasError && (
        <Alert appearance="danger">
          خطایی در ثبت پیش‌سفارش. لطفا با پشتیبانی در تماس باشید.
        </Alert>
      )}

      <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
        <Button type="submit" appearance="primary" shouldFitContainer>
          {isSubmitting ? (
            <SpinnerLoading CustomClass="-translate-y-1" />
          ) : (
            "ثبت پیش‌سفارش"
          )}
        </Button>
        <Button appearance="secondary" onClick={handleClose} shouldFitContainer>
          مطمئن نیستم!
        </Button>
      </div>
    </form>
  );
};

export default PreOrderForm;
