import React from "react";

// Icons
import Calling from "../assets/icons/Calling";
import Email from "../assets/icons/Email";
import Telegram from "../assets/icons/Telegram";
import Instagram from "../assets/icons/Instagram";

interface FooterElementsProps {
  children: React.ReactNode;
  title: string;
}

const SocialMedia = [
  {
    label: "Telegram",
    icon: <Telegram />,
    to: "https://t.me/nikkyshop_support",
  },
  {
    label: "Instagram",
    icon: <Instagram />,
    to: "https://www.instagram.com/nikkyshop.official",
  },
];

const FooterElements = ({ children, title }: FooterElementsProps) => {
  return (
    <div className="lg:w-[32%] w-full lg:min-h-64 min-h-fit">
      <div className="w-full border-b border-white after:w-[148px] after:h-[5px] after:rounded-full after:bg-info-dark after:top-[95%] after:right-0 after:absolute relative p-4">
        <h4 className=" font-Pinar text-xl font-extrabold ">{title}</h4>
      </div>
      <div className="px-2 py-4 gap-4 flex flex-col">{children}</div>
    </div>
  );
};

const Footer = () => {
  return (
    <>
      <div className="flex flex-col lg:flex-row justify-between gap-6 items-center mt-16">
        <FooterElements title="راه های ارتباطی">
          <div className="flex items-center gap-2">
            <Calling />
            <p>شماره پشتیبانی: ۰۹۳۶۶۹۵۵۵۸۸</p>
          </div>
          <div className="flex items-center gap-2">
            <Email />
            <p>ایمیل: support@nikky.shop</p>
          </div>
        </FooterElements>

        <FooterElements title="شبکه‌های اجتماعی">
          <div className="flex gap-8 items-center px-2 py-4">
            {SocialMedia.map((item, index) => (
              <a
                key={index}
                href={item?.to}
                target="_blank"
                aria-label={item?.label}
              >
                <div className="p-3 rounded-lg bg-gray35">{item.icon}</div>
              </a>
            ))}
          </div>
        </FooterElements>

        <FooterElements title="نماد اعتماد">
          <div className="flex">
            <a
              referrerPolicy="origin"
              target="_blank"
              href="https://trustseal.enamad.ir/?id=428531&Code=Qtgkh6Fn91Olg0uuxE6NrYg0agnFJbkM"
              aria-label="Enamad"
            >
              <img
                referrerPolicy="origin"
                src="https://trustseal.enamad.ir/logo.aspx?id=428531&Code=Qtgkh6Fn91Olg0uuxE6NrYg0agnFJbkM"
                alt=""
                style={{ cursor: "pointer" }}
                data-code="Qtgkh6Fn91Olg0uuxE6NrYg0agnFJbkM"
              />
            </a>
          </div>
        </FooterElements>
      </div>
    </>
  );
};

export default Footer;
