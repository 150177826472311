import HeaderCover from "../../assets/images/header-cover.webp";
import LeftArrow from "../../assets/icons/LeftArrow";

const IntroductionSection = () => {
  const handleStart = () => {
    const element = document.getElementById("sources");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section className=" flex items-center justify-between lg:flex-row flex-col gap-2">
      <div className="lg:w-[50%] w-full flex flex-col justify-center lg:items-start items-center gap-6 ">
        <p className="bg-landingBg-blue w-fit rounded-[20px] rounded-es-none px-3 py-1 opacity-70">
          هارمونی استایل؛ کوک شده با حال شما
        </p>
        <h1 className="text-[52px] font-black font-Pinar">نیکــی‌ شـاپ</h1>
        <div className="opacity-70 font-normal text-[18px] text-center lg:text-justify">
          نیکی شاپ برای خلق یک تجربه منحصر به فرد در کنارته. با نیکی شاپ می‌تونی
          موسیقی مورد علاقه‌ات رو به تصویر بکشی. فقط کافیه بعد از انتخاب موسیقی
          مورد علاقه‌ات بخشی از آهنگ که قلاب دلت بیشتر بهش گیر کرده رو انتخاب
          کنی و منتظر خلق یک طرح منحصر به خودت باشی.
        </div>
        <button
          className="border-white border rounded-[10px] flex items-center gap-3 w-fit py-4 px-6"
          role="button"
          aria-label="order"
        >
          <div className="font-bold text-[16px]" onClick={handleStart}>
            یه اثر انحصاری بساز
          </div>
          <LeftArrow />
        </button>
      </div>
      <figure className="lg:w-[50%] w-full flex justify-center lg:items-start mt-5 lg:mt-0">
        <img src={HeaderCover} alt="نمونه تیشرت" />
      </figure>
    </section>
  );
};

export default IntroductionSection;
