// Components
import player from "../../../../assets/player.png";
import playerWhite from "../../../../assets/player-white.png";
import SpinnerLoading from "../../../../common/SpinnerLoading";

const PrintPreview = ({
  imageUrl,
  text,
  color,
  isLoading,
}: {
  imageUrl: string | undefined;
  text: string | undefined;
  color: "light" | "dark";
  isLoading: boolean;
}) => {
  return (
    <div className="absolute bottom-[42%] right-[49%] w-[40%] sm:w-[25%] translate-x-1/2 translate-y-1/2">
      <div className="lg:gap-3 gap-2 flex flex-col">
        {isLoading ? (
          <div className="w-full aspect-square flex justify-center items-center">
            <SpinnerLoading />
          </div>
        ) : (
          <div
            className="w-full aspect-square"
            style={{
              backgroundImage: `url(${imageUrl})`,
              backgroundSize: "contain",
            }}
          ></div>
        )}

        <p
          className={`font-Pinar text-center text-[10px] lg:text-lg whitespace-pre-wrap ${
            color === "light" ? "text-black" : "text-white"
          }`}
        >
          {text}
        </p>
        <div>
          <img src={color === "light" ? player : playerWhite} alt="player" />
        </div>
      </div>
    </div>
  );
};

export default PrintPreview;
