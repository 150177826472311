import { useEffect, useState } from "react";

// Assets
import Logo from "../../../../assets/logo.svg";

// Icon
import UpArrow from "../../assets/icons/UpArrow";
import { Link } from "react-router-dom";

// TODO: Why ReviewSection?
const ReviewSection = () => {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  useEffect(() => {
    window.scroll({
      top: position.top,
      left: position.left,
      behavior: "smooth",
    });
  });

  return (
    <div className="flex justify-center lg:justify-between  items-center my-16">
      <div className="flex items-center gap-2">
        <div>
          <img
            src={Logo}
            alt="Nikky.shop"
            className="w-16 h-16 hidden lg:flex"
          />
        </div>
        <div className="flex flex-col gap-2">
          <h1 className="font-black text-base hidden lg:flex">نیکی شاپ</h1>
          <p className="font-semibold text-base opacity-70 hidden lg:flex">
            هارمونی استایل؛ کوک شده با حال شما
          </p>
        </div>
      </div>
      <button
        role="button"
        onClick={() => setPosition({ ...position })}
        className="border-white border rounded-[10px] items-center p-4 hidden lg:flex cursor-pointer"
        aria-label="Back to top"
      >
        <UpArrow />
      </button>
      <div className="lg:hidden flex flex-col gap-12 items-center justify-center">
        <h3 className="font-black text-5xl">نیکی شاپ</h3>
        <div className="flex items-center justify-center gap-4">
          <Link to={"/help"} className="font-semibold text-base opacity-70">
            راهنما
          </Link>
          <Link to={"/faq"} className="font-semibold text-base opacity-70">
            سوالات متداول
          </Link>
          <Link to={"/suport"} className="font-semibold text-base opacity-70">
            پشتیبانی
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ReviewSection;
