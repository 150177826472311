import { Outlet } from "react-router-dom";

// Context
import { OrderProvider } from "../../../contexts/OrderProvider";

// Components
import Progress from "../components/Layout/Progress";

const OrderingLayout = () => {
  return (
    <div className="grid grid-cols-12 mb-12 w-full">
      <div className="lg:col-start-3 col-span-12 lg:col-span-8">
        <Progress />
        <div className="mt-20">
          <OrderProvider>
            <Outlet />
          </OrderProvider>
        </div>
      </div>
    </div>
  );
};

export default OrderingLayout;
