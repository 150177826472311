const ShortcutIcon = ({
  color,
  opacity = 1,
}: {
  color: string;
  opacity?: number;
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke={color}
      strokeOpacity={opacity}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16675 9.16658L2.33342 2.33325"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66675 5.66675V1.66675H5.66675"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8333 1.66675H12.4999C16.6666 1.66675 18.3333 3.33342 18.3333 7.50008V12.5001C18.3333 16.6668 16.6666 18.3334 12.4999 18.3334H7.49992C3.33325 18.3334 1.66658 16.6668 1.66658 12.5001V10.8334"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShortcutIcon;
