import { Link } from "react-router-dom";

// Components
import NavbarMenu from "./Menu";
import ShoppingCart from "./Cart";

// Assets
import Logo from "../../../assets/logo.svg";

const Navbar = () => {
  return (
    <nav className="sticky top-0 mt-7 py-7 bg-primary-dark z-50">
      <div className="grid grid-cols-12">
        <div className="col-start-2 col-span-10 md:col-start-3 md:col-span-8">
          <div className="flex justify-between items-center">
            <Link to="/">
              <img src={Logo} alt="Nikky.shop" className="w-12 h-12 md:w-16 md:h-16" />
            </Link>

            <NavbarMenu />

            <ShoppingCart />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
